import { BrandclubStoreConfigUtils } from "@brandclub/common-ui";
import { BrandAppConfig } from "../redux/types";

export const BRANDCLUB_APP_CONFIG_ID = "BRANDCLUB";

export default class ConfigUtils {
  public static getAppStage(): "localhost" | "beta" | "gamma" | "prod" {
    return BrandclubStoreConfigUtils.getAppStage();
  }

  public static getWebAppId() {
    return window.location.hostname;
  }

  public static getApiHostName(): string {
    return BrandclubStoreConfigUtils.getApiHostName();
  }

  public static getServerSideSyncHostName(): string {
    return BrandclubStoreConfigUtils.getServerSideSyncHostName();
  }

  public static getEdgeCachedApiHostName(): string {
    return BrandclubStoreConfigUtils.getEdgeCachedApiHostName();
  }

  public static getGraphQlUri(): string {
    return BrandclubStoreConfigUtils.getGraphQlUri();
  }

  public static getServerSideSyncGraphQlUri(): string {
    return BrandclubStoreConfigUtils.getServerSideSyncGraphQlUri();
  }

  public static getBranchIOLinkPrefix(brandAppConfig: BrandAppConfig): string {
    return brandAppConfig?.branchIOConfig?.mobileAppBranchLink || "";
  }

  public static getBranchDownloadAppLink(
    brandAppConfig: BrandAppConfig
  ): string {
    if (brandAppConfig.appConfigId !== "BRANDCLUB") {
      const baseUrl = this.getBranchIOLinkPrefix(brandAppConfig);
      return new URL("/install", baseUrl).toString();
    } else {
      // TODO: White Label - Remove this after Universal Store goes live
      const appStage = BrandclubStoreConfigUtils.getAppStage();
      if (["prod", "gamma"].includes(appStage)) {
        return `https://brandclub.app.link/SbrHYoFexdb`;
      }
      return `https://brandclub.test-app.link/0ZtIlexz6eb`;
    }
  }
}
