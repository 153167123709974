import React from "react";
import { RewardListType } from "./RewardList";
import { Backdrop, Dialog, styled, useTheme } from "@mui/material";
import {
  CheckCircleIcon,
  CloseButton,
  hexadecimal,
} from "@brandclub/common-ui";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";
import { decodeHTMLCharacters } from "@stackline/ui";

const sectionHeight = 150;
export interface RewardCardRenderingProps {
  id: string;
  show: boolean;
  icon: string;
  showRoundedIcon?: boolean;
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  amount?: string;
  linkTitle: string;
  linkSubtitle1?: string;
  linkSubtitle2?: string;
  linkIndicatorDot?: "yellow" | "purple" | "blue" | "green" | "red";
  steps?: any[];
  currentStep?: number;
}

const INDICATOR_COLOR_MAPPING = {
  yellow: "#ffbd32",
  blue: "#5b71b2",
  red: "#de6055",
  purple: "#a21671",
  green: "#71c184",
};

const CardContainer = styled("div")(({ theme }) => ({
  border: "1.5px solid #dcdddc",
  borderRadius: 10,
  // width: "100%",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    border: "1px solid #dcdddc",
  },

  ".top_container": {
    padding: "17px 25px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      padding: "15px 12px",
    },
    ".icon": {
      width: 80,
      height: 80,
      marginRight: 25,
      objectFit: "contain",
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        width: 50,
        height: 50,
      },
    },
    ".detail": {
      flex: 1,
      minWidth: 0,
      ".card_title": {
        fontSize: 18,
        fontWeight: 600,
        color: theme.palette.primary.main,
        width: "100%",
        overflow: "hidden",
        letterSpacing: 0.36,
        WebkitLineClamp: 1,
        lineHeight: 1.5,
        height: `1.5em`, // line height * 1
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        wordBreak: "break-word",
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 14,
        },
      },
      ".card_subtitle": {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.primary.main,
        width: "100%",
        overflow: "hidden",
        letterSpacing: 0.36,
        WebkitLineClamp: 1,
        lineHeight: 1.5,
        height: `1.5em`, // line height * 1
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        wordBreak: "break-word",
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 10,
        },
      },
    },
    ".amount": {
      marginLeft: 25,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      ".amount_title": {
        fontSize: 16,
        fontWeight: 500,
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 10,
        },
      },
      ".amount_value": {
        fontSize: 18,
        fontWeight: 600,
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 16,
        },
      },
    },
  },
  ".bot_container": {
    // height: 50,
    display: "flex",
    padding: "17px 25px",
    backgroundColor: "#f3f4f5",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      padding: "15px 12px",
    },
    ".arrow": {
      fontSize: 35,
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 15,
      },
    },
    ".link_title": {
      display: "flex",
      alignItems: "center",
      fontSize: 16,
      fontWeight: 600,
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 10,
      },
      ".card_indicator_dot": {
        display: "inline-block",
        marginLeft: 8,
        height: 8,
        width: 8,
        borderRadius: 8,
        [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          height: 5,
          width: 5,
          borderRadius: 5,
        },
      },
    },
    ".link_subtitle": {
      display: "flex",
      alignItems: "center",
      fontSize: 14,
      fontWeight: 500,
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 10,
      },
    },
  },
}));

const RewardDetailContainer = styled("div")(({ theme }) => ({
  width: 581,
  maxWidth: 581,
  borderRadius: 10,
  padding: "35px 50px 40px 50px",
  position: "relative",
  boxSizing: "border-box",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: "unset",
    maxWidth: "unset",
    padding: "15px 20px 20px 20px",
  },
  ".close": {
    cursor: "pointer",
    position: "absolute",
    top: 20,
    right: 20,
    width: 20,
    height: 20,
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      top: 10,
      right: 10,
      width: 20,
      height: 20,
    },
    svg: {
      width: 13,
      height: 13,
      objectFit: "contain",
    },
  },
  ".header": {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.primary.main,
    margin: "10px 0 26px 0",
  },
  ".step_warp": {
    display: "flex",
    gap: 26,
    ".indicator": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      ".progress_bg": {
        width: 14,
        borderRadius: 6,
        height: "100%",
        backgroundColor: "#e4eaef",
        ".progress_bar": {},
      },
      ".progress_bar": {
        backgroundColor: theme.palette.secondary.main,
      },

      ".dot": {
        width: 30,
        height: 30,
        display: "flex",
        zIndex: 1,
        alignItems: "center",
        justifyContent: "center",
        ".check_icon": {
          width: 18,
          height: 18,
          objectFit: "contain",
        },
      },
    },
    ".stepper": {
      display: "flex",
      flexDirection: "column",
      gap: 70,
      ".single_step": {
        maxWidth: 374,
        ".title": {
          display: "flex",
          height: 30,
          alignItems: "center",
          fontSize: 20,
          fontWeight: 600,
          color: theme.palette.primary.main,
          [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            fontSize: 16,
          },
        },
        ".desc": {
          fontSize: 14,
          fontWeight: 500,
          color: theme.palette.primary.main,
          height: 50,
          [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            fontSize: 12,
          },
        },
      },
    },
  },
}));

const RewardDetail = ({
  reward,
  onClose,
}: {
  reward: RewardCardRenderingProps;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const steps = reward.steps ?? [];
  const currentIndex = reward.currentStep ?? 0;
  return (
    <RewardDetailContainer>
      <div className="close">
        <CloseButton iconSize={12.5} onClick={onClose} />
      </div>
      <div className="header">Reward details</div>

      <div className="step_warp">
        <div className="indicator">
          <div
            style={{
              top: 9,
              width: 11,
              height: (steps.length - 1) * sectionHeight + 8,
              backgroundColor: "#e4eaef",
              borderRadius: 10,
              position: "absolute",
            }}
          />
          <motion.div
            className="progress_bar"
            style={{
              top: 9,
              width: 11,
              height:
                (currentIndex === 0 ? 0.3 : currentIndex) * sectionHeight + 8,

              borderRadius: 10,
              position: "absolute",
            }}
            initial={{
              height: 0,
            }}
            animate={{
              height:
                (currentIndex === 0 ? 0.3 : currentIndex) * sectionHeight + 8,
            }}
            transition={{
              type: "timing",
              duration: steps.length * 0.5,
              delay: 0,
            }}
          ></motion.div>
          {steps.map((step, idx) => {
            const isLast = idx === steps.length - 1;
            if (idx <= currentIndex) {
              return (
                <motion.div
                  key={idx}
                  className="dot"
                  style={{
                    marginBottom: isLast ? 30 : sectionHeight - 30,
                  }}
                  initial={{
                    opacity: 0,
                    scale: 0,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  transition={{
                    type: "timing",
                    delay: 0.5 * idx,
                    duration: 0.5,
                  }}
                >
                  <CheckCircleIcon
                    backgroundColor="primary"
                    sx={{ fontSize: 18 }}
                    htmlColor="#ffffff"
                  />
                </motion.div>
              );
            } else {
              return (
                <motion.div
                  key={idx}
                  className="dot"
                  style={{
                    marginBottom: isLast ? 30 : sectionHeight - 30,
                  }}
                  initial={{
                    opacity: 0,
                    scale: 0,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  transition={{
                    type: "timing",
                    delay: 0.5 * idx,
                    duration: 0.5,
                  }}
                >
                  <div
                    style={{
                      width: 9,
                      height: 9,
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 5,
                    }}
                  />
                </motion.div>
              );
            }
          })}
        </div>
        <div className="stepper">
          {steps?.map((step, index) => {
            return (
              <motion.div
                initial={{ opacity: 0, x: 10 }}
                animate={{
                  opacity: 1,
                  x: 0,
                }}
                transition={{
                  type: "timing",
                  delay: 0.5 * index,
                  duration: 0.5,
                }}
                key={index}
                className="single_step"
              >
                <div className="title">{step?.title}</div>
                <div className="desc">{step?.detail}</div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </RewardDetailContainer>
  );
};

const RewardCard = ({
  item,
  type,
}: {
  item: RewardCardRenderingProps;
  type: RewardListType;
}) => {
  const navigate = useNavigate();

  const [openRewardDetail, setOpenRewardDetail] = React.useState(false);

  const closeRewardDetail = () => {
    setOpenRewardDetail(false);
  };
  const handleClicked = () => {
    if (type === "payout") {
      navigate(`/dashboard/rewards/payouts/${item?.id}`);
    } else {
      if (item?.steps?.length) {
        setOpenRewardDetail(true);
      }
    }
  };

  if (!item.show) {
    return null;
  }

  return (
    <>
      <CardContainer
        style={{ cursor: item?.steps?.length ? "pointer" : "auto" }}
        onClick={handleClicked}
      >
        <div className="top_container">
          <img 
            className="icon"
            alt="i_logo" 
            src={item.icon}
            style={{ borderRadius: item.showRoundedIcon ? '50%' : '0' }}
          />
          <div className="detail">
            <div className="card_title">
              {decodeHTMLCharacters(item?.title ? `${item?.title}` : "")}
            </div>
            <div className="card_subtitle">
              {decodeHTMLCharacters(
                item?.subtitle1 ? `${item?.subtitle1}` : ""
              )}
            </div>
            <div className="card_subtitle">
              {decodeHTMLCharacters(
                item?.subtitle2 ? `${item?.subtitle2}` : ""
              )}
            </div>
          </div>
          {!!item.amount && (
            <div className="amount">
              <div className="amount_title">Amount</div>
              <div className="amount_value">{item.amount}</div>
            </div>
          )}
        </div>
        <div className="bot_container">
          <div>
            <div className="link_title">
              {item.linkTitle}
              {item.linkIndicatorDot ? (
                <div
                  className="card_indicator_dot"
                  style={{
                    backgroundColor:
                      INDICATOR_COLOR_MAPPING[item.linkIndicatorDot],
                  }}
                />
              ) : null}
            </div>
            <div className="link_subtitle">{item.linkSubtitle1}</div>
            <div className="link_subtitle">{item.linkSubtitle2}</div>
          </div>

          {type === "payout" || item?.steps?.length ? (
            <ChevronRightRoundedIcon className="arrow" />
          ) : null}
        </div>
      </CardContainer>

      <Dialog
        slots={{
          backdrop: () => (
            <Backdrop
              onClick={closeRewardDetail}
              open={openRewardDetail}
              sx={(theme) => ({
                backgroundColor: hexadecimal(theme.palette.primary.main)(21),
              })}
            />
          ),
        }}
        open={openRewardDetail}
        onClose={(e) => closeRewardDetail()}
      >
        <RewardDetail reward={item} onClose={closeRewardDetail} />
      </Dialog>
    </>
  );
};

export default RewardCard;
