export class LottieUtils {
  /**
   * Extracts all unique colors used in a Lottie JSON file.
   *
   * @param lottieJson - The Lottie JSON data.
   * @returns An array of unique colors found in the Lottie file, represented as hex strings.
   */
  static getAllColors(lottieJson: any): string[] {
    const colorSet = new Set<string>();

    if (Array.isArray(lottieJson.layers)) {
      for (const layer of lottieJson.layers) {
        if (Array.isArray(layer.shapes)) {
          this.extractColorsFromShapes(layer.shapes, colorSet);
        }

        if (layer.layers) {
          for (const subLayer of layer.layers) {
            if (Array.isArray(subLayer.shapes)) {
              this.extractColorsFromShapes(subLayer.shapes, colorSet);
            }
          }
        }
      }
    }

    return Array.from(colorSet);
  }

  /**
   * Recursively extracts colors from shapes and adds them to the color set.
   *
   * @param shapes - An array of shape objects.
   * @param colorSet - A Set to store unique color hex strings.
   */
  static extractColorsFromShapes(shapes: any[], colorSet: Set<string>): void {
    for (const shape of shapes) {
      if (shape.it && Array.isArray(shape.it)) {
        this.extractColorsFromShapes(shape.it, colorSet);
      }

      if (shape.ty === "gr" && shape.it && Array.isArray(shape.it)) {
        this.extractColorsFromShapes(shape.it, colorSet);
      }

      if (shape.ty === "fl" && shape.c) {
        this.extractColorFromProperty(shape.c, colorSet);
      }

      if (shape.ty === "st" && shape.c) {
        this.extractColorFromProperty(shape.c, colorSet);
      }
    }
  }

  /**
   * Extracts colors from a color property (static or animated) and adds them to the color set.
   *
   * @param colorProp - The color property object.
   * @param colorSet - A Set to store unique color hex strings.
   */
  static extractColorFromProperty(colorProp: any, colorSet: Set<string>): void {
    if (colorProp.a === 0) {
      const k = colorProp.k;
      if (Array.isArray(k) && (k.length === 3 || k.length === 4)) {
        const colorHex = this.rgbaToHex(k);
        colorSet.add(colorHex);
      }
    }
  }

  /**
   * Replaces colors in the Lottie JSON based on a dictionary of color mappings.
   *
   * @param lottieJson - The Lottie JSON data.
   * @param colorMapping - A dictionary where the key is the original color and the value is the new color.
   * @returns Modified Lottie JSON data.
   */
  static replaceColors(
    lottieJson: any,
    colorMapping: { [key: string]: string }
  ): any {
    if (Array.isArray(lottieJson.layers)) {
      for (const layer of lottieJson.layers) {
        if (Array.isArray(layer.shapes)) {
          this.replaceColorsInShapes(layer.shapes, colorMapping);
        }
        if (layer.layers) {
          for (const subLayer of layer.layers) {
            if (Array.isArray(subLayer.shapes)) {
              this.replaceColorsInShapes(subLayer.shapes, colorMapping);
            }
          }
        }
      }
    }
    return lottieJson;
  }

  /**
   * Recursively replaces colors in shapes.
   *
   * @param shapes - An array of shape objects.
   * @param colorMapping - A dictionary of color mappings.
   */
  static replaceColorsInShapes(
    shapes: any[],
    colorMapping: { [key: string]: string }
  ): void {
    for (const shape of shapes) {
      if (shape.it && Array.isArray(shape.it)) {
        this.replaceColorsInShapes(shape.it, colorMapping);
      }

      if (shape.ty === "fl" && shape.c) {
        this.replaceColorInProperty(shape.c, colorMapping);
      }

      if (shape.ty === "st" && shape.c) {
        this.replaceColorInProperty(shape.c, colorMapping);
      }
    }
  }

  /**
   * Replaces color in a property if it matches the color in the mapping.
   *
   * @param colorProp - The color property object.
   * @param colorMapping - A dictionary of color mappings.
   */
  static replaceColorInProperty(
    colorProp: any,
    colorMapping: { [key: string]: string }
  ): void {
    if (colorProp.a === 0) {
      const k = colorProp.k;
      if (Array.isArray(k) && (k.length === 3 || k.length === 4)) {
        const currentColor = this.rgbaToHex(k);
        if (colorMapping[currentColor]) {
          colorProp.k = this.hexToRgba(colorMapping[currentColor]);
        }
      }
    }
  }

  /**
   * Converts an RGBA array to a hex string.
   *
   * @param rgba - The RGBA array.
   * @returns The corresponding hex string.
   */
  static rgbaToHex(rgba: number[]): string {
    return (
      "#" +
      rgba
        .slice(0, 3)
        .map((channel) =>
          Math.round(channel * 255)
            .toString(16)
            .padStart(2, "0")
        )
        .join("")
    );
  }

  /**
   * Converts a hex string to an RGBA array.
   *
   * @param hex - The hex string.
   * @returns The corresponding RGBA array.
   */
  static hexToRgba(hex: string): number[] {
    let r = 0,
      g = 0,
      b = 0,
      a = 255;

    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }

    return [r / 255, g / 255, b / 255, a / 255];
  }
}

export default LottieUtils;
