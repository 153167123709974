import { ProductRewardSearchResult } from "@brandclub/common-ui";
import {
  BrandClub,
  PaymentStatus,
  ProductId,
  PromoteBrandClubCashBackReward,
  RepeatPurchaseCashBackReward,
  ReviewCashBackReward,
  RewardStatus,
  UserStatus,
} from "@brandclub/types";
import { Address } from "../redux/types";

export interface Brand {
  brandName: string;
  brandId: number;
}

export type CampaignStatus = "live" | "paused" | "ended" | "outOfBudget";

export interface RepeatPurchaseRewardCampaignsForList {
  campaignName: string;
  campaignType: string;
  campaignId: string;
  campaignStatus: CampaignStatus;
  budgetAmountRemaining: number;
  budgetAmountTotal: number;
  endDate: Date;
  createdTime: string;
  startDate: Date;
  isWildcardCampaign: boolean;
  extendedAttributes: {
    reward: RepeatPurchaseCashBackReward;
  };
  rewardProducts: {
    retailerId: number;
    retailerSku: string;
    brandName: string;
    title: string;
  }[];
}

export interface ReviewRewardCampaignsForList {
  campaignName: string;
  campaignType: string;
  campaignStatus: CampaignStatus;
  campaignId: string;
  budgetAmountRemaining: number;
  budgetAmountTotal: number;
  endDate: Date;
  isWildcardCampaign: boolean;
  startDate: Date;
  createdTime: string;
  extendedAttributes: {
    reward: ReviewCashBackReward;
  };
  rewardProducts: {
    retailerId: number;
    retailerSku: string;
    brandName: string;
    title: string;
  }[];
}

export interface PromoteBrandClubRewardCampaignsForList {
  campaignName: string;
  campaignType: string;
  brandclubIds: string[];
  campaignStatus: CampaignStatus;
  createdTime: string;
  campaignId: string;
  budgetAmountRemaining: number;
  budgetAmountTotal: number;
  isWildcardCampaign: boolean;
  endDate: Date;
  startDate: Date;
  extendedAttributes: {
    reward: PromoteBrandClubCashBackReward;
  };
  brandclubs: {
    name: string;
    imageUrl: string;
    brands?: {
      name: string;
    }[];
  }[];
}

export interface WaitingListInvitation {
  id: string;
  name: string;
  email?: string;
  phoneNumber?: string;
  createdAt: number;
  updatedAt: number;
  status: string;
  userStatus?: string;
  emailInvitationStatus: string;
  textInvitationStatus: string;
  extendedAttributes: Record<string, unknown>;
}

export type SearchRequestBody = {
  titleInclude?: string[];
  titleExclude?: string[];
  retailPrice?: {
    min?: number;
    max?: number;
  }[];
  totalRewardAmount?: {
    min?: number;
    max?: number;
  };
  campaignTypes?: string[];
  reviewsRating?: {
    min?: number;
    max?: number;
  };
  reviewsCount?: {
    min?: number;
    max?: number;
  };
  brandClubMatchingId?: string;
  brandIds?: number[];
  departmentIds?: number[];
  categoryIds?: number[];
  subCategoryIds?: number[];
  retailerIds?: number[];
  isBrandClubReady?: boolean;
};

export type SearchRequest = {
  body: SearchRequestBody;
  sort?: {
    fieldName: string;
    direction: "asc" | "desc";
  }[];
  page?: {
    start: number;
    size: number;
  };
  facets?: {
    computeFacets: boolean;
    facetFieldNames?: string[];
  };
  wildcardRewardsByRetailer?: Record<number, unknown[]>;
  additionalExcludes?: string[];
};
export interface PageFilter {
  start: number;
  size: number;
}

export interface CustomerCashBackRewardForTable {
  rewardType:
    | "purchaseCashBackReward"
    | "customerReferralReward"
    | "productReviewCashBackReward"
    | "promoteBrandClubCashBackReward"
    | "socialReward"
    | "surveyReward"
    | "retailerConnectReward"
    | "pageScanReward"
    | "contentReward"
    | "affiliateLinkCashBackReward"
    | "limitedTimePurchaseCashBackReward"
    | "syncReward"
    | "cashOutFeeReward";
  rewardStatus: RewardStatus;
  paymentStatus: PaymentStatus;
  rewardUnlockStrategy?: "instant" | "brandedPurchase";
  rewardId: string;
  rewardAmount: number;
  updatedTime: string;
  createdTime: string;
  brandClub?: BrandClub;
  productId?: ProductId;
  expiresAt?: string | number;
  referredUserName?: string;
  extendedAttributes: {
    rewardUnlockStrategy?: "instant" | "brandedPurchase";
    actionType?: "join" | "referral";
    brandId?: number;
    brandName?: string;
    stacklineSku?: string;
    title?: string;
    retailPrice?: number;
    brandclubId?: number;
    retailerId?: number;
    brandIds?: number[];
    validUntil?: string; // date string
    orderId?: string;
    productId?: {
      retailerId?: number;
      retailerSku?: string;
      stacklineSku?: string;
    };
    additionalMetaData?: {
      brandId: number;
      brandName: string;
      stacklineSku: string;
      title: string;
      retailPrice: number;
      retailerId: number;
    };
  };
}

export interface CustomerReferralCashBackRewardForTable {
  rewardType: 'customerReferralReward';
  rewardStatus: RewardStatus;
  paymentStatus: PaymentStatus;
  rewardId: string;
  rewardAmount: number;
  updatedTime: string;
  createdTime: string;
  extendedAttributes: Record<string, any> & {
    appId?: string;
    appConfigId?: string;
    brandId?: number;
    installationId: string;
    referralCode: string;
    installingUserId: string;
    rewardUnlockStrategy: RewardUnlockStrategy;
    rewardOriginAppType?: string;
    referringUserId: string;
    companyId: string;
    divisionId: string;
    validUntil: Date;
    expiredAt?: Date;
    deletedAt?: Date;
    attribution?: string;
    userStatus?: UserStatus;
  };
}

export interface Payout {
  transactionId: string;
  transactionType: "credit" | "debt";
  transactionAmount: number;
  transactionStatus: "pending" | "success" | "failed";
  updatedTime: string;
  createdTime: string;
  // rewards: { rewardId: string }[];
  recipientWalletType: "PAYPAL" | "VENMO";
  payoutBreakdown: {
    cashOutFeeAmount: number;
    payoutEligibleAmount: number;
    payoutEligibleRewardCount: number;
  };
  extendedAttributes: {
    rewardIds?: string[];
    userStatus?: string;
    payoutResult?: {
      links: Array<{
        rel: string;
        href: string;
        method: string;
        encType: string;
      }>;
      payout_item: {
        note: string;
        amount: {
          value: string;
          currency: string;
        };
        receiver: string;
        recipient_type: string;
        sender_item_id: string;
        recipient_wallet: string;
      };
      payout_item_id: string;
      payout_batch_id: string;
      payout_item_fee: {
        value: string;
        currency: string;
      };
      transaction_status: string;
      items?: Array<{
        links: Array<{
          rel: string;
          href: string;
          method: string;
          encType: string;
        }>;
        payout_item: {
          note: string;
          amount: {
            value: string;
            currency: string;
          };
          receiver: string;
          recipient_type: string;
          sender_item_id: string;
          recipient_wallet: string;
        };
        payout_item_id: string;
        payout_batch_id: string;
        payout_item_fee: {
          value: string;
          currency: string;
        };
        transaction_status: string;
      }>;
      batch_header?: {
        amount: {
          value: string;
          currency: string;
        };
        batch_status: string;
        time_created: string;
        funding_source: string;
        payout_batch_id: string;
        sender_batch_header: {
          email_subject: string;
          sender_batch_id: string;
        };
      };
      httpStatusCode?: number;
    };
  };
}

export interface PayoutWithRewards extends Payout {
  rewards: {
    rewardType: string;
    rewardStatus: string;
    rewardAmount: number;
    expiresAt: Date;
    updatedTime: Date;
    createdTime: Date;
    rewardId: string;
    paymentStatus: string;
    extendedAttributes: Record<string, unknown>;
    brandClub: {
      brandclubId: string;
      name: string;
      imageUrl: string;
    };
  }[];
}

export type CountBreakdownAnswerState = { [category: string]: number };

export type SurveyQuestionType =
  | "singleChoice"
  | "multipleChoice"
  | "longAnswer"
  | "shortAnswer"
  | "likert"
  | "image"
  | "date"
  | "countBreakdown"
  | "video"
  // New on mar 2024
  | "pills"

  // Are these ones actually used?
  | "squaretype1"
  | "squaretype2"
  | "rectanglevertical"
  | "rectangleHorizontal";

export type ResponseOptionsMetaData = {
  layoutMode:
    | "default"
    | "square"
    | "rectangleHorizontal"
    | "rectangleVertical"
    | "condensedChoices"
    | "nps";
  notApplicableLabel?: string;
  optionsMetaData: {
    displayText: string;
    optionType: "text" | "image" | "video";
    sourceUrl?: string;
    thumbnailUrl?: string;
    processedUrl?: string;
  }[];
};
export interface SurveyQuestion {
  description: string;
  type: SurveyQuestionType;
  required?: boolean;
  minResponseLength?: number;
  maxResponseLength?: number;
  responseOptions?: string[];
  responseOptionsMetaData?: ResponseOptionsMetaData;
  canSelectMultipleResponses?: boolean;
  validationQuestionId?: string; // Question description, e.g. "Even or odd?"
  imageUrl?: string;
}
export interface CarouselPage {
  title: string;
  subtitle?: string;
  isIntroPage?: boolean;
  mediaUrl?: string;
  mediaType?: "video" | "image";
  preTitle?: string;
  thumbnail?: string;
  question?: SurveyQuestion;
}
export interface Content {
  description: string;
  title: string;
  successTitle: string;
  successSubtitle: string;
  pagesForCarousel: CarouselPage[];
  previewImageUrl?: string;
  mediaDurationInSeconds?: number;
}

export enum RewardUnlockStrategy {
  Instant = "instant",
  // Branded Purchase will be assumed default, if missing
  BrandedPurchase = "brandedPurchase",
}
export interface ContentRewardCampaignForCarousel {
  extendedAttributes: {
    rewardUnlockStrategy: RewardUnlockStrategy;
    reward: {
      reward: {
        amount: number;
      };
    };
    contentId: string;
  };
  campaignId: string;
  content: Content;
  hasRewardForContent: boolean;
  brandclub?: {
    brandId: number;
    imageUrl: string;
    name: string;
    brandclubId: string;
  };
}

export interface Survey {
  description: string;
  surveyQuestions: SurveyQuestion[];
}

export interface SurveyRewardCampaignForCarousel {
  extendedAttributes: {
    rewardUnlockStrategy: RewardUnlockStrategy;
    reward: {
      reward: {
        amount: number;
      };
    };
    surveyId: string;
  };
  campaignId: string;
  survey: Survey;
  brandclub?: BrandClub & { brandId: number };
}

export interface SurveyResponse {
  type: SurveyQuestionType;
  text?: string;
  selectedOptions?: string[];
  value?: CountBreakdownAnswerState;
  isValidateAnswer?: boolean;
}

export interface ReviewProductResponse {
  ReviewProducts: {
    items: ProductRewardSearchResult[];
    hasMore: boolean;
    nextPage: PageFilter | null;
  };
}

export enum ShipmentStatus {
  DELIVERED = "DELIVERED",
  CANCELLED = "CANCELLED", //returned back or cancelled
  SHIPPED = "SHIPPED",
  UNKNOWN = "UNKNOWN",
}

export interface Category {
  categoryId: number;
  categoryName: string;
  parentCategoryId: number;
  parentCategoryName: string;
  relevancyScore: number;
}

export enum StoreBrandingType {
  CustomDTCStore = "CustomDTCStore",
  CustomStore = "CustomStore",
  UniversalStore = "UniversalStore",
}

export enum SiteBrandingType {
  CustomDTCStore = "CustomDTCStore",
  CustomStore = "CustomStore",
  BrandStore = "BrandStore",
  UniversalStore = "UniversalStore",
}

export interface UserPurchaseHistoryItem {
  brandId: number;
  status: string;
  quantity: number;
  retailPrice: number;
  retailerId: number;
  retailerSku: string;
  stacklineSku: string;
}

export interface UserPurchaseHistoryProduct extends ProductRewardSearchResult {
  reward?: CustomerCashBackRewardForTable;
  orderTracking?: {
    shipmentStatus?: ShipmentStatus;
  };
  retailerOrderPageUrl?: string;
}

export type UserPurchaseHistory = {
  items: UserPurchaseHistoryItem[];
  products: {
    [key: string]: UserPurchaseHistoryProduct;
  };
  checkoutBatchId: string;
  orderId: string;
  billingAddress?: Address;
  shippingAddress?: Address;
  retailerOrderId: string | null;
  status: string;
  updatedTime: string;
  userId: string;
  paymentMethod: any;
  orderSummary: any;
  retailerId: number;
};

export enum MfaValues {
  MfaEnter = "mfa-enter",
  MfaChoose = "mfa-choose",
}

export type EventSequence = {
  id: string;
  initiator: string;
  timestamp: string;
  type: MfaValues;
  data: {
    required: boolean;
    type: string;
    otpOptions: string[];
    selectedOption?: string;
    otpValue?: string;
    displayText?: string;
  };
};

export type SyncStatus =
  | "queued"
  | "pending"
  | "connecting"
  | "userintervention"
  | "connected"
  | "scanning"
  | "scanning_completed"
  | "processing_rewards"
  | "completed"
  | "skipped"
  | "timed_out"
  | "error";

export type RetailerSyncSession = {
  syncId: string;
  retailerId: number;
  syncStatus: SyncStatus;
  createdAt: number;
  eventSequence?: EventSequence;
  errorMessage?: string;
};

export type ParentRetailerSyncSession = {
  parentRetailerId: number;
  syncId: string;
  syncStatus: SyncStatus;
  createdAt: number;
  eventSequence?: EventSequence;
};

export type SyncSession = {
  syncBatchId: string;
  retailerSyncSessions: RetailerSyncSession[];
  sessionType: "liveShopping" | "sync" | "reviews";
};

export type RetailerConnectionState =
  | "valid"
  | "invalid"
  | "retry"
  | "validating"
  | "notConnected";

export type RetailerConnections = {
  [key: string]: {
    connectionStatus: RetailerConnectionState;
    lastSyncTime: string | null;
  };
};

/**
 * Represents a type that makes certain properties optional while keeping the rest of the properties unchanged.
 *
 * @typeparam T - The original type.
 * @typeparam K - The keys of the properties to make optional.
 */
export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
