import { ChevronDownIcon } from "@brandclub/common-ui";
import { Box } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import _startCase from "lodash/startCase";
import CardBrandIcon from "../../../pages/AppPages/Dashboard/Wallet/CardBrandIcon";
import { StoreAnimatedHeightDiv } from "../../../StoreComponents/StoreAnimatedHeightDiv";
import { useCheckoutContext } from "../../CheckoutMachine";
import { ShippingAddress, UserRetailerCheckoutOrder } from "../../types";
import CheckoutEditPaymentForm from "./CheckoutEditPaymentForm";
import InfoBox from "./InfoBox";
import { useEffect, useState } from "react";
import { ERROR_MESSAGES } from "../../../../utils/errors/errorUtils";
import { UserInputUpdateType } from "../../CheckoutMachine/types/GlobalCheckoutMachine";
import { useOrder } from "../../OrderProvider";
import { useSelector } from "@xstate/react";

interface PaymentMethodProps {
  paymentMethod: UserRetailerCheckoutOrder["paymentMethod"];
  onAddPaymentMethod: () => void;
}
const PaymentMethod = (props: PaymentMethodProps) => {
  const { paymentMethod } = props;
  if (!paymentMethod) {
    return (
      <InfoBox
        message="Please add a payment method to complete this purchase."
        cta="Add new payment method"
        onClick={props.onAddPaymentMethod}
      />
    );
  }
  return (
    <div className="info_box">
      <div className="info_row">
        <CardBrandIcon
          className="icon"
          alt="icon"
          cardBrand={paymentMethod?.cardIssuer?.toLocaleLowerCase()}
        />
        {_startCase(paymentMethod?.cardIssuer || "")} ending in{" "}
        {paymentMethod?.cardNumberEnding}
      </div>
    </div>
  );
};
const PaymentSection = () => {
  const { openPayment, setOpenPayment } = useOrder();

  const { actorRef, send } = useCheckoutContext();
  const userRetailerCheckoutSessionStatus = useSelector(
    actorRef,
    (snapshot) =>
      snapshot.context.retailerCheckoutOrderStatus
        ?.userRetailerCheckoutSessionStatus
  );
  const userRetailerCheckoutOrder = useSelector(
    actorRef,
    (snapshot) =>
      snapshot.context.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
  );
  const userInputError = useSelector(actorRef, (snapshot) =>
    snapshot.context.userInputUpdateType &&
    [
      UserInputUpdateType.UPDATE_PAYMENT_METHOD,
      UserInputUpdateType.ADD_PAYMENT_METHOD,
    ].includes(snapshot.context.userInputUpdateType)
      ? snapshot.context.userInputError
      : undefined
  );
  const errorMessage =
    userRetailerCheckoutSessionStatus?.lastFailedAttempt?.type;

  const [error, setError] = useState("");

  useEffect(() => {
    if (
      errorMessage === "addPaymentMethod" ||
      errorMessage === "updatePaymentMethod" ||
      !!userInputError
    ) {
      setError(ERROR_MESSAGES.paymentMethodInvalid);
      setOpenPayment(true);
    }
  }, [errorMessage, userInputError, setOpenPayment]);

  const handleUpdatePayment = async (
    method: "add" | "update",
    cardInfo: {
      cardNumber?: string;
      cardIssuer: string;
      expirationDate: string;
      cvv?: string;
      cardHolderName: string;
      cardNumberEnding?: string;
    },
    address?: Partial<ShippingAddress>
  ) => {
    try {
      setError("");
      if (method === "add") {
        send({
          type: "CHECKOUT_USER_INPUT_EVENT",
          inputEvent: UserInputUpdateType.ADD_PAYMENT_METHOD,
          data: {
            cardInfo,
            billingAddress: address as ShippingAddress,
          },
        });
      } else {
        send({
          type: "CHECKOUT_USER_INPUT_EVENT",
          inputEvent: UserInputUpdateType.UPDATE_PAYMENT_METHOD,
          data: {
            cardInfo,
            billingAddress: address as ShippingAddress,
          },
        });
      }
    } catch (e) {
      console.error(e);
      setError(ERROR_MESSAGES.paymentMethodInvalid);
    }
  };

  const availablePayments =
    userRetailerCheckoutOrder?.availablePaymentMethods?.filter((payment) => {
      return !payment.expirationDate.includes("Expired");
    }) ?? [];

  return (
    <Box className="section">
      <div
        className="section_head"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setError("");
          setOpenPayment(!openPayment);
        }}
      >
        <div className="title">{"Payment Method"}</div>
        <ChevronDownIcon
          style={{
            transition: "transform 0.3s",
            width: "33px",
            height: "33px",
            transform: openPayment ? "rotate(180deg)" : "rotate(0deg)",
          }}
        ></ChevronDownIcon>
      </div>
      <StoreAnimatedHeightDiv>
        <AnimatePresence mode="wait">
          <motion.div
            layout="position"
            key={openPayment ? "view" : "edit"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            {openPayment ? (
              <CheckoutEditPaymentForm
                error={error}
                billingAddress={userRetailerCheckoutOrder?.billingAddress}
                availablePayments={availablePayments}
                handleUpdatePayment={handleUpdatePayment}
                existingAddress={userRetailerCheckoutOrder?.billingAddress}
                cancelEditing={() => {
                  setOpenPayment(false);
                }}
              />
            ) : (
              <PaymentMethod
                paymentMethod={userRetailerCheckoutOrder?.paymentMethod}
                onAddPaymentMethod={() => {
                  setOpenPayment(true);
                }}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </StoreAnimatedHeightDiv>
    </Box>
  );
};

export default PaymentSection;
