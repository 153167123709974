import {
  getPurchaseRewardAmountForProductV2,
  ProductRewardSearchResult,
} from "@brandclub/common-ui";
import { scaleFont } from "@/utils/font";
import {
  ClickAwayListener,
  Popper,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState, useEffect } from "react";
import { formatMoney } from "../../../../../utils/StringUtils";
import {
  OutlineButton,
  SolidButton,
} from "../../../../StoreComponents/StoreButton";
import BagButton from "./BagButton";
import HamburgerMenuButton from "./HamburgerMenuButton";
import { useNavigate } from "react-router-dom";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../AppNavigation/constants";
import { motion } from "framer-motion";
import { useAppSelector } from "@/redux/hooks";
import { getCartCounts } from "@/redux/selectors";

const Container = styled(motion.div)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "fixed",
  bottom: 0,
  zIndex: 10,
  width: "100%",
  backgroundColor: "white",
  boxShadow: "0 -0px 10px 0px rgba(195, 188, 188, 0.15)",
}));

const TopBar = styled("div")(({ theme }) => ({
  "*": {
    fontFamily: `${
      theme?.barFonts?.fontFamily ? theme?.barFonts?.fontFamily + ", " : ""
    }Poppins, sans-serif !important`,
  },
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  gap: "10px",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "5px 24px",
  minHeight: "74px",
  height: "86px",
  borderTop: "1px solid rgba(220, 221, 220, 0.5)",

  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    padding: "5px 11px",
  },
}));

const HamburgerMenu = styled("div")({
  display: "none",
  alignItems: "center",
  padding: "11px",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    display: "flex",
  },
});

const Dropdown = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  padding: "12px 8px",
  boxSizing: "border-box",
  border: "1px solid #dcdddc80",
  backgroundColor: " #ffffff",
  shadow: "0 3px 15px 0 rgba(195, 188, 188, 0.15)",
  borderRadius: "8px",
  minWidth: "180px",
});

const RewardsInfo = styled("div")(({ theme }) => ({
  display: "none",
  justifyContent: "center",
  alignItems: "center",
  color: "var(--color-primary)",
  paddingBottom: "10px",
  fontWeight: 500,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    display: "flex",
    baseFontSize: scaleFont(theme.baseFontSize, 12),
  },
}));

const RewardsInfoDesktop = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "var(--color-primary)",
  fontSize: scaleFont(theme.baseFontSize, 16),
  cursor: "pointer",

  fontWeight: 500,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    display: "none",
  },
}));

const FlexEndContainer = styled("div")(() => ({
  display: "flex",
  flex: 1,
  justifyContent: "flex-end",
  gap: "10px",
  alignItems: "center",
}));

const BagButtonContainer = styled("div")(() => ({
  display: "flex",
  gap: "10px",
  alignItems: "center",
}));

const AddToBagButtonWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ".dot": {
    height: "6px",
    width: "6px",
    margin: "0 6px",
    borderRadius: "50%",
  },
});

const BarOutlineButton = styled(OutlineButton)(({ theme }) => ({
  fontSize: scaleFont(theme.baseFontSize, 16),
  fontWeight: 500,
  borderWidth: 1,
  borderColor: "#dcdddc80",
  width: "fit-content",
  height: 52,
  padding: "0px 20px",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    height: 46,
    fontSize: scaleFont(theme.baseFontSize, 14),
  },
}));

const getAllProductsReward = (products: ProductRewardSearchResult[]) => {
  const rewards = products
    .map((product) => getPurchaseRewardAmountForProductV2(product).amount)
    .filter((reward) => reward > 0);
  const totalRewards = rewards.reduce((acc, reward) => acc + reward, 0);
  const rewardCount = rewards.length;
  return [totalRewards, rewardCount];
};

const formatProductReward = (rewardAmount: number, numberOfRewards: number) => {
  if (numberOfRewards > 1) {
    return `${formatMoney(rewardAmount)} in rewards`;
  }
  return `${formatMoney(rewardAmount)} reward`;
};

const MenuContainer = styled("div")(() => ({
  display: "flex",
  gap: "10px",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    display: "none",
  },
}));

const menuItems = [
  {
    label: "Profile",
    to: "/profile",
  },
  {
    label: "Rewards",
    to: "/rewards/balance",
  },
  {
    label: "Accounts",
    to: "/accounts",
  },
  {
    label: "Purchases",
    to: "/purchase",
  },
  {
    label: "Dashboard",
    to: "/me",
  },
];

const DashboardBottomBar = () => {
  const theme = useTheme();

  const mobileView = useMediaQuery(`(max-width:${TABLET_SCREEN_SIZE}px)`);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const cartCount = useAppSelector(getCartCounts);
  const { products } = useAppSelector((state) => state?.checkout);

  const navigate = useNavigate();
  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const productsArr = Object.values(products || {});

  const [purchaseRewardAmount, rewardCount] = getAllProductsReward(productsArr);
  const shouldShowBar = mobileView ? true : cartCount > 0;

  const onCheckout = () => {
    navigate("checkout");
  };

  useEffect(() => {
    const footerElement = document.getElementById("dtc_footer");
    if (footerElement && shouldShowBar) {
      footerElement.style.paddingBottom = "86px";
    } else if (footerElement && !shouldShowBar) {
      footerElement.style.paddingBottom = "0px";
    }

    return () => {
      if (footerElement) {
        footerElement.style.paddingBottom = "0px";
      }
    };
  }, [shouldShowBar]);

  const open = Boolean(anchorEl);
  const id = open ? "menu-popper" : undefined;

  if (!shouldShowBar) {
    return null;
  }

  const outlinedButtonBorderColor = theme?.componentStyleOverrides
    ?.outlinedButton?.enabled
    ? theme?.componentStyleOverrides?.outlinedButton?.tertiary
    : "#dcdddc";

  const outlinedButtonTextColor = theme?.componentStyleOverrides?.outlinedButton
    ?.enabled
    ? theme?.componentStyleOverrides?.outlinedButton?.primary
    : theme.palette.primary.main;

  const addToBagButtonBgColor = theme?.componentStyleOverrides?.addToBagButton
    .enabled
    ? theme?.componentStyleOverrides?.addToBagButton.secondary
    : theme.palette.secondary.main;

  const addToBagButtonOutlineColor =
    theme?.componentStyleOverrides?.addToBagButton?.enabled &&
    theme?.componentStyleOverrides?.addToBagButton?.borderEnabled
      ? theme?.componentStyleOverrides?.addToBagButton.tertiary
      : undefined;

  const addToBagButtonTextColor = theme?.componentStyleOverrides?.addToBagButton
    ?.enabled
    ? theme?.componentStyleOverrides?.addToBagButton.primary
    : theme.palette.primary.main;

  return (
    <Container
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.6, ease: "easeInOut" }}
      className="bottom_container"
    >
      <TopBar>
        <MenuContainer>
          <BarOutlineButton
            sx={{
              borderColor: outlinedButtonBorderColor,
              color: outlinedButtonTextColor,
              fontWeight: theme.fontWeight,
              textTransform: theme.componentStyleOverrides?.outlinedButton
                ?.capitalize
                ? "uppercase"
                : "none",
            }}
            onClick={(e) => {
              navigate("/me");
            }}
          >
            Dashboard
          </BarOutlineButton>
        </MenuContainer>

        <HamburgerMenu
          onClick={handleToggleMenu}
          aria-describedby={id}
          role="button"
        >
          <HamburgerMenuButton />
        </HamburgerMenu>

        <Popper
          placement="top-start"
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [10, 28],
              },
            },
          ]}
          className="menu-popper"
          sx={{
            zIndex: 1,
          }}
          id={id}
          open={open}
          anchorEl={anchorEl}
        >
          <ClickAwayListener onClickAway={handleToggleMenu as any}>
            <Dropdown>
              {menuItems.map((item) => {
                return (
                  <BarOutlineButton
                    sx={{
                      color: outlinedButtonTextColor,
                      fontWeight: theme.fontWeight,
                    }}
                    key={item.label}
                    onClick={(e) => {
                      navigate(item.to);
                      handleToggleMenu(e);
                    }}
                  >
                    {item.label}
                  </BarOutlineButton>
                );
              })}
            </Dropdown>
          </ClickAwayListener>
        </Popper>

        <FlexEndContainer>
          {cartCount ? (
            <>
              {!!purchaseRewardAmount ? (
                <RewardsInfoDesktop
                  onClick={onCheckout}
                  sx={{
                    fontWeight: theme.fontWeight,
                  }}
                >
                  {formatProductReward(purchaseRewardAmount, rewardCount)}
                </RewardsInfoDesktop>
              ) : null}
              <SolidButton
                sx={(theme) => ({
                  backgroundColor: addToBagButtonBgColor,
                  color: addToBagButtonTextColor,
                  border: addToBagButtonOutlineColor
                    ? `1px solid ${addToBagButtonOutlineColor}`
                    : "none",
                  textTransform: theme.componentStyleOverrides?.addToBagButton
                    ?.capitalize
                    ? "uppercase"
                    : "none",
                  fontSize: scaleFont(theme.baseFontSize, 16),
                  fontWeight: theme.fontWeight,
                  width: "fit-content",
                  height: 52,
                  padding: "0px 20px",
                  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
                    fontSize: scaleFont(theme.baseFontSize, 14),
                    display: "flex",
                    width: "100%",
                    height: 46,
                    maxWidth: "unset",
                  },
                  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                    maxWidth: "unset",
                  },
                })}
                onClick={onCheckout}
              >
                <AddToBagButtonWrapper
                  sx={{
                    color: addToBagButtonTextColor,
                    ".dot": {
                      backgroundColor: addToBagButtonTextColor,
                    },
                    fontWeight: theme.fontWeight,
                  }}
                >
                  Checkout
                </AddToBagButtonWrapper>
              </SolidButton>
            </>
          ) : null}
        </FlexEndContainer>
        <BagButtonContainer>
          <BagButton
            onClick={() => {
              navigate("/mybag");
            }}
          />
        </BagButtonContainer>
      </TopBar>
      {!!purchaseRewardAmount && (
        <RewardsInfo
          sx={{
            fontWeight: theme.fontWeight,
          }}
        >
          {formatMoney(purchaseRewardAmount)} in rewards
        </RewardsInfo>
      )}
    </Container>
  );
};

export default DashboardBottomBar;
