import { useContext, useEffect, useState } from "react";
import StoreInput from "../../StoreComponents/StoreInput";
import AuthSolidButton from "./components/AuthSolidButton";
import { ReactComponent as AddUser } from "../../../assets/Add_User.svg";
import {
  formatPhone,
  getPhoneNumberMasking,
  validatePhone,
} from "../../../utils/StringUtils";
import { ButtonLoadingEllipsis } from "../../StoreComponents/StoreButton";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { UserLoginContext } from "./UserLoginProvider";
import { clearBrandEntity } from "../../../redux/reducers/brandEntity";
import { TermsAndConditions } from "./TermsAndConditions";
import { ButtonBase, useTheme } from "@mui/material";
import { BRANDCLUB_BRAND_ID } from "@brandclub/common-ui";
import { AuthHelper } from "./AuthHelper";
import OTPWrapper from "./OTPWrapper";
import { ReactComponent as RightArrow } from "../../../assets/right-arrow.svg";
import { saveNewPhoneNumber, Status } from "./updatePhone/api";
import { NumberFormatCustom } from "@/components/StoreComponents/NumberFormatCustom";

const ROW_SPACING = 20;

const SignIn = ({
  isUpdatePhoneFlow = false,
}: {
  isUpdatePhoneFlow?: boolean;
}) => {
  const { snapshot, send } = useContext(UserLoginContext);
  const appConfig = useAppSelector(({ appConfig }) => appConfig);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (appConfig?.domainConfig.brandId === BRANDCLUB_BRAND_ID) {
      dispatch(clearBrandEntity());
    }
  }, [dispatch, appConfig?.domainConfig.brandId]);

  const [phone, setPhone] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(
    snapshot.context.errorMessage
  );
  const theme = useTheme();

  const signInWithPhone = async (isNewUser: boolean) => {
    try {
      const phoneNumber = formatPhone(phone);
      const session = await AuthHelper.sendAuthOtpWithSms(
        phoneNumber,
        isNewUser
      );
      return send({
        type: "MOVE_TO_VERIFY_OTP",
        cognitoSession: session,
        otpPhoneNumber: phoneNumber,
        isNewUser,
      });
    } catch (e: any) {
      if ((e.name || e.code) === "UserNotFoundException") {
        // signUpWithPhone();
      } else {
        setErrorMsg(`An error occurred: Please try again after some time`);
        console.error(e);
      }
    }
  };

  const submit = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();
    const phoneNumber = formatPhone(phone);

    if (isUpdatePhoneFlow) {
      const session = snapshot.context.session;
      const sessionId = snapshot.context.sessionId;
      if (!session || !sessionId) {
        throw new Error("Session not found");
      }
      try {
        const { status, errorMessage } = await saveNewPhoneNumber({
          session,
          sessionId,
          phoneNumber,
        });
        if (status === Status.success) {
          await signInWithPhone(false);
        } else {
          setErrorMsg(
            errorMessage ||
              `An error occurred: Please try again after some time`
          );
        }
      } catch (e) {
        setErrorMsg(`An error occurred: Please try again after some time`);
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    } else {
      setSubmitting(true);
      try {
        await AuthHelper.signUp(phoneNumber);
        return await signInWithPhone(true);
      } catch (e: any) {
        if (e.code === "UsernameExistsException") {
          return await signInWithPhone(false);
        } else {
          setErrorMsg(`An error occurred: Please try again after some time`);
          console.error(e);
        }
      } finally {
        setSubmitting(false);
      }
    }
  };

  const clearErrors = () => {
    setErrorMsg("");
  };

  const isNumberValid = validatePhone(phone);

  const phoneMasking = getPhoneNumberMasking(phone || "");

  return (
    <OTPWrapper>
      <div className="auth_container">
        <AddUser
          style={{
            width: 75,
            height: 75,
            fill: theme.palette.primary.main,
          }}
        />
        <div className="page_title">Enter your phone number</div>
        <div className="page_subtitle">
          We will text you a verification code.
        </div>
        <StoreInput
          errorMsg={errorMsg || ""}
          value={phone}
          autoFocus
          onChange={(e) => {
            const value = e.target.value;
            setPhone(value?.trim());
            clearErrors();
          }}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            inputProps: {
              format: phoneMasking,
            },
          }}
          placeholder="Phone number"
          inputStyle={{ width: "100%" }}
          style={{ marginTop: ROW_SPACING }}
        ></StoreInput>
        <AuthSolidButton
          onClick={submit}
          disabled={submitting || !isNumberValid}
          style={{ marginTop: ROW_SPACING }}
        >
          {submitting ? (
            <>
              Submitting
              <ButtonLoadingEllipsis
                blue
                baseFontSize={16}
                customColor="#dcdddc"
              />
            </>
          ) : (
            "Continue"
          )}
        </AuthSolidButton>
        {isUpdatePhoneFlow ? null : (
          <ButtonBase
            onClick={() => send({ type: "CHANGE_PHONE_NUMBER" })}
            sx={{
              marginTop: "30.5px",
              fontWeight: 500,
              display: "flex",
              fontSize: "14px",
              lineHeight: "22px",
              flexDirection: "row",
              alignItems: "center",
              gap: "6.5px",
            }}
          >
            Update your phone number <RightArrow />
          </ButtonBase>
        )}
        <div className="termsAgreement" style={{ maxWidth: 300 }}>
          <TermsAndConditions />
        </div>
      </div>
    </OTPWrapper>
  );
};

export default SignIn;
