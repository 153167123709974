import { Backdrop, styled, useTheme } from "@mui/material";
import { useCheckoutContext } from "../../CheckoutMachine";
import SpinAnimation from "../../../../assets/lottie/fill_cycle_spin.json";
import LottieInner from "react-lottie";
import { progressLottieOptions } from "../../../../utils/lottieUtils";
import {
  GlobalCheckoutState,
  UserInputUpdateType,
} from "../../CheckoutMachine/types/GlobalCheckoutMachine";
import { useSelector } from "@xstate/react";

// @ts-ignore
const Lottie = LottieInner.default
  ? // @ts-ignore
    LottieInner.default
  : LottieInner;

const BackdropContent = styled("div")(({ theme }) => ({
  maxWidth: 250,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 7,
  ".spinner": {
    width: 71,
    height: 71,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 4,
  },
  ".title": {
    coler: theme.palette.primary.main,
    textAlign: "center",
    fontSize: 16,
    fontWeight: 700,
  },
  ".subtitle": {
    coler: theme.palette.primary.main,
    textAlign: "center",
    fontSize: 14,
    fontWeight: 500,
  },
}));

const USER_INPUT_STATUS_MESSAGE = {
  [GlobalCheckoutState.WAIT_FOR_CHECKOUT_SUCCESS_DATA_LOAD]: {
    title: "We're placing your order",
    subtitle: "Stay tuned for a moment while we wrap up the last details",
  },
  [UserInputUpdateType.UPDATE_PAYMENT_CONFIRMATION_AND_COMPLETE_CHECKOUT]: {
    title: "We're placing your order",
    subtitle: "Stay tuned for a moment while we wrap up the last details",
  },
  [UserInputUpdateType.UPDATE_PAYMENT_METHOD]: {
    title: "Give us just a second",
    subtitle: "We're updating your payment method",
  },
  [UserInputUpdateType.UPDATE_SHIPPING_ADDRESS]: {
    title: "Give us just a second",
    subtitle: "We're updating your shipping address",
  },
  [UserInputUpdateType.ADD_SHIPPING_ADDRESS]: {
    title: "Give us just a second",
    subtitle: "We're updating your shipping address",
  },
  [UserInputUpdateType.UPDATE_SHIPPING_OPTION]: {
    title: "Give us just a second",
    subtitle: "We're updating your shipping choices",
  },
  [UserInputUpdateType.ADD_PAYMENT_METHOD]: {
    title: "Give us just a second",
    subtitle: "We’re updating your payment method",
  },
  [UserInputUpdateType.REMOVE_PARTIALLY_OUT_OF_STOCK_ITEM]: {
    title: "Give us just a second",
    subtitle: "We're updating your order",
  },
};

const CheckoutLoadingOverlay = () => {
  const theme = useTheme();
  const { actorRef } = useCheckoutContext();
  const userInputUpdateType = useSelector(
    actorRef,
    (snapshot) => snapshot.context.userInputUpdateType
  );
  const globalCheckoutState = useSelector(
    actorRef,
    (snapshot) => snapshot.context.globalCheckoutState
  );
  let title: string | undefined;
  let subtitle: string | undefined;
  const status =
    globalCheckoutState ===
    GlobalCheckoutState.WAIT_FOR_CHECKOUT_SUCCESS_DATA_LOAD
      ? globalCheckoutState
      : userInputUpdateType;
  if (status) {
    title = USER_INPUT_STATUS_MESSAGE[status].title;
    subtitle = USER_INPUT_STATUS_MESSAGE[status].subtitle;
  }
  const hasMessage = Boolean(title);

  return (
    <Backdrop
      sx={{
        color: (theme) => theme.palette.primary.main,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "rgba(255, 255, 255, 0.95)",
      }}
      open={hasMessage}
    >
      <BackdropContent>
        <div className="spinner">
          <div style={{ position: "absolute" }}>
            <Lottie
              options={progressLottieOptions(theme, SpinAnimation)}
              isClickToPauseDisabled
              width={160}
              height={160}
            />
          </div>
        </div>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </BackdropContent>
    </Backdrop>
  );
};

export default CheckoutLoadingOverlay;
