import { createMachine, DoneActorEvent, fromPromise, assign } from "xstate";
import {
  AddPaymentMethodEvent,
  AddShippingAddressEvent,
  CheckoutUserInputEvent,
  CheckoutUserInputMachineContext,
  CheckoutUserInputMachineEvent,
  RemovePartaillyOutOfStockItemEvent,
  UpdatePaymentConfirmationAndCompleteCheckoutEvent,
  UpdatePaymentMethodEvent,
  UpdateShippingAddressEvent,
  UpdateShippingOptionEvent,
} from "./types/CheckoutUserInputMachine";
import { getActiveCarts } from "@/redux/reducers/checkout/thunk";
import {
  addPaymentMethodToUserRetailerCheckoutOrder,
  addShippingToUserRetailerCheckoutOrder,
  removeItemFromShoppingCart,
  confirmCheckout,
  updatePaymentMethodToUserRetailerCheckoutOrder,
  updateShippingOption,
  updateShippingToUserRetailerCheckoutOrder,
  RetailerCheckoutOrderStatus,
  deleteItemsInCheckoutPage,
} from "@/api/rest/checkoutApi";
import { UserInputUpdateType } from "./types/GlobalCheckoutMachine";
import { removeErrorProduct } from "../utils";

export const CheckoutUserInputMachine = (() => {
  return createMachine(
    {
      id: "checkoutUserInputMachine",
      initial: "extractEvent",
      types: {} as unknown as {
        context: CheckoutUserInputMachineContext;
        events: CheckoutUserInputMachineEvent;
      },
      context: ({ input: { context } }: { input: { context: any } }) =>
        context as CheckoutUserInputMachineContext,
      states: {
        extractEvent: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "extractEvent",
            onDone: [
              {
                target: "handleUpdateShippingAddress",
                guard: "isUpdateShippingAddressEvent",
              },
              {
                target: "handleAddShippingAddress",
                guard: "isAddShippingAddressEvent",
              },
              {
                target: "handleUpdateShippingOption",
                guard: "isUpdateShippingOptionEvent",
              },
              {
                target: "handleUpdatePaymentMethod",
                guard: "isUpdatePaymentMethodEvent",
              },
              {
                target: "handleAddPaymentMethod",
                guard: "isAddPaymentMethodEvent",
              },
              {
                target: "removePartiallyOutOfStockItem",
                guard: "isRemovePartiallyOutOfStockItemEvent",
              },
              {
                target: "handleUpdatePaymentConfirmationAndCompleteCheckout",
                guard: "isUpdatePaymentConfirmationAndCompleteCheckout",
              },
            ],
          },
        },
        handleUpdateShippingAddress: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleUpdateShippingAddress",
            onDone: {
              target: "handleSuccess",
              actions: assign({
                retailerCheckoutOrderStatus: ({ event }) => {
                  return event.output;
                },
              }),
            },
          },
        },
        handleAddShippingAddress: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleAddShippingAddress",
            onDone: {
              target: "handleSuccess",
              actions: assign({
                retailerCheckoutOrderStatus: ({ event }) => {
                  return event.output;
                },
              }),
            },
          },
        },
        handleUpdateShippingOption: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleUpdateShippingOption",
            onDone: {
              target: "handleSuccess",
              actions: assign({
                retailerCheckoutOrderStatus: ({ event }) => {
                  return event.output;
                },
              }),
            },
          },
        },
        handleUpdatePaymentMethod: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleUpdatePaymentMethod",
            onDone: {
              target: "handleSuccess",
              actions: assign({
                retailerCheckoutOrderStatus: ({ event }) => {
                  return event.output;
                },
              }),
            },
          },
        },
        handleAddPaymentMethod: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleAddPaymentMethod",
            onDone: {
              target: "handleSuccess",
              actions: assign({
                retailerCheckoutOrderStatus: ({ event }) => {
                  return event.output;
                },
              }),
            },
          },
        },
        handleUpdatePaymentConfirmationAndCompleteCheckout: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleUpdatePaymentConfirmationAndCompleteCheckout",
            onDone: {
              target: "handleSuccess",
              actions: assign({
                retailerCheckoutOrderStatus: ({ event }) => {
                  return event.output;
                },
              }),
            },
          },
        },
        removePartiallyOutOfStockItem: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "removePartiallyOutOfStockItem",
            onDone: {
              target: "handleSuccess",
              actions: assign({
                retailerCheckoutOrderStatus: ({ event }) => {
                  return event.output;
                },
              }),
            },
          },
        },
        handleSuccess: {
          type: "final",
        },
      },
      output: ({ context }) => ({
        userInputUpdateType: context.userInput
          ?.inputEvent as UserInputUpdateType,
        error: context.error,
        retailerCheckoutOrderStatus: context.retailerCheckoutOrderStatus,
      }),
    },
    {
      actors: {
        extractEvent: fromPromise(
          async ({
            input: { context },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
            };
          }) => {
            return context?.userInput;
          }
        ),
        handleUpdateShippingAddress: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
              event: DoneActorEvent<UpdateShippingAddressEvent>;
            };
          }) => {
            const { activeCheckoutOrder } = context;
            if (activeCheckoutOrder) {
              const res = await updateShippingToUserRetailerCheckoutOrder({
                retailerId: activeCheckoutOrder.retailerId,
                orderId: activeCheckoutOrder.orderId,
                shippingAddress: event.output.data.address,
              });
              if (res.status === "error") {
                throw new Error(res.errorMessage);
              }
              return res;
            }
          }
        ),
        handleAddShippingAddress: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
              event: DoneActorEvent<AddShippingAddressEvent>;
            };
          }) => {
            const { activeCheckoutOrder } = context;
            if (activeCheckoutOrder) {
              const res = await addShippingToUserRetailerCheckoutOrder({
                retailerId: activeCheckoutOrder.retailerId,
                orderId: activeCheckoutOrder.orderId,
                shippingAddress: event.output.data.address,
              });
              if (res.status === "error") {
                throw new Error(res.errorMessage);
              }
              return res;
            }
          }
        ),
        handleUpdateShippingOption: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
              event: DoneActorEvent<UpdateShippingOptionEvent>;
            };
          }) => {
            const { activeCheckoutOrder } = context;
            if (activeCheckoutOrder) {
              const res = await updateShippingOption({
                retailerId: activeCheckoutOrder.retailerId,
                orderId: activeCheckoutOrder.orderId,
                deliveryOption: event.output.data.deliveryOption,
                deliveryTimeWindow: event.output.data.deliveryTimeWindow,
              });
              if (res.status === "error") {
                throw new Error(res.errorMessage);
              }
              return res;
            }
          }
        ),
        handleUpdatePaymentMethod: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
              event: DoneActorEvent<UpdatePaymentMethodEvent>;
            };
          }) => {
            const { activeCheckoutOrder } = context;
            if (activeCheckoutOrder) {
              const res = await updatePaymentMethodToUserRetailerCheckoutOrder({
                retailerId: activeCheckoutOrder.retailerId,
                orderId: activeCheckoutOrder.orderId,
                cardInfo: event.output.data.cardInfo,
                billingAddress: event.output.data.billingAddress,
              });
              if (res.status === "error") {
                throw new Error(res.errorMessage);
              }
              return res;
            }
          }
        ),
        handleAddPaymentMethod: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
              event: DoneActorEvent<AddPaymentMethodEvent>;
            };
          }) => {
            const { activeCheckoutOrder } = context;
            if (activeCheckoutOrder) {
              const res = await addPaymentMethodToUserRetailerCheckoutOrder({
                retailerId: activeCheckoutOrder.retailerId,
                orderId: activeCheckoutOrder.orderId,
                cardInfo: event.output.data.cardInfo,
                billingAddress: event.output.data.billingAddress,
              });

              if (res.status === "error") {
                throw new Error(res.errorMessage);
              }
              return res;
            }
          }
        ),
        handleUpdatePaymentConfirmationAndCompleteCheckout: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: CheckoutUserInputMachineContext;
              event: DoneActorEvent<UpdatePaymentConfirmationAndCompleteCheckoutEvent>;
            };
          }) => {
            const { activeCheckoutOrder, retailerCheckoutOrderStatus } =
              context;
            if (activeCheckoutOrder) {
              return await confirmCheckout({
                retailerId: activeCheckoutOrder.retailerId,
                orderId: activeCheckoutOrder.orderId,
                paymentConfirmation:
                  retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
                    ?.paymentMethod?.paymentConfirmationTypes?.[0]?.type &&
                  event.output.data.paymentConfirmation
                    ? {
                        type: retailerCheckoutOrderStatus
                          ?.userRetailerCheckoutOrder?.paymentMethod
                          ?.paymentConfirmationTypes?.[0]?.type,
                        value: event.output.data.paymentConfirmation,
                      }
                    : undefined,
              });
            }
          }
        ),
        removePartiallyOutOfStockItem: fromPromise<
          RetailerCheckoutOrderStatus | undefined,
          {
            context: CheckoutUserInputMachineContext;
            event: DoneActorEvent<RemovePartaillyOutOfStockItemEvent>;
          }
        >(async ({ input: { context, event } }) => {
          const order = context.retailerCheckoutOrderStatus;
          const { stacklineSku } = event.output.data;
          const removeItem = order?.userRetailerCheckoutOrder?.items.find(
            (item: any) => item.stacklineSku === stacklineSku
          );
          if (!order || !removeItem) {
            return;
          }
          const remainingItemsAfterRemoval =
            order?.userRetailerCheckoutOrder?.items.filter(
              (item: any) => item.stacklineSku !== removeItem.stacklineSku
            );

          const updatedOrder = (await removeErrorProduct(
            stacklineSku,
            order
          )) as RetailerCheckoutOrderStatus;
          const updatedOrderStillHasRemovedItem =
            updatedOrder?.userRetailerCheckoutOrder?.shippingOptions?.shippingGroups.some(
              (group) =>
                group.products.some(
                  (p) => p.retailerSku === removeItem.retailerSku
                )
            );
          if (updatedOrderStillHasRemovedItem) {
            const request = {
              retailerId: order?.userRetailerCheckoutOrder?.retailerId,
              orderId: order?.userRetailerCheckoutOrder?.orderId,
              brandId: order?.userRetailerCheckoutOrder?.brandId,
              items: [
                {
                  quantity: removeItem?.quantity,
                  retailPrice: removeItem?.retailPrice,
                  retailerSku: removeItem?.retailerSku,
                  retailerId: removeItem?.retailerId,
                },
              ],
            };
            await deleteItemsInCheckoutPage(request);
          }
          if (
            remainingItemsAfterRemoval?.length === 0 &&
            order?.userRetailerCheckoutOrder
          ) {
            await removeItemFromShoppingCart({
              retailerId: order?.userRetailerCheckoutOrder?.retailerId,
              items: [removeItem],
            });
            await getActiveCarts();
          }
          return updatedOrder;
        }),
      },
      guards: {
        isUpdateShippingAddressEvent: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return inputEvent === UserInputUpdateType.UPDATE_SHIPPING_ADDRESS;
        },
        isAddShippingAddressEvent: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return inputEvent === UserInputUpdateType.ADD_SHIPPING_ADDRESS;
        },
        isUpdateShippingOptionEvent: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return inputEvent === UserInputUpdateType.UPDATE_SHIPPING_OPTION;
        },
        isUpdatePaymentMethodEvent: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return inputEvent === UserInputUpdateType.UPDATE_PAYMENT_METHOD;
        },
        isAddPaymentMethodEvent: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return inputEvent === UserInputUpdateType.ADD_PAYMENT_METHOD;
        },
        isUpdatePaymentConfirmationAndCompleteCheckout: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return (
            inputEvent ===
            UserInputUpdateType.UPDATE_PAYMENT_CONFIRMATION_AND_COMPLETE_CHECKOUT
          );
        },
        isRemovePartiallyOutOfStockItemEvent: ({ event }) => {
          const { inputEvent } = (
            event as DoneActorEvent<CheckoutUserInputEvent>
          ).output;
          return (
            inputEvent ===
            UserInputUpdateType.REMOVE_PARTIALLY_OUT_OF_STOCK_ITEM
          );
        },
      },
    }
  );
})();
