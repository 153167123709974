import { useSelector } from "@xstate/react";
import FlowContainer from "../../../pages/AppPages/Pages/components/FlowContainer";
import EnterMFACode from "../../../pages/AppPages/Sync/MFA/EnterMFACode";
import { useCheckoutContext } from "@/components/Checkout/CheckoutMachine";
import { styled } from "@mui/material";
import { TABLET_SCREEN_SIZE } from "@brandclub/common-ui";

const StyledEnterMFACode = styled(EnterMFACode)({
  ".auth_container": {
    [`@media screen and (min-width: ${TABLET_SCREEN_SIZE}px)`]: {
      paddingTop: 175,
    },
  },
});

const CheckoutMFA = () => {
  const { actorRef } = useCheckoutContext();
  const retailerSyncSessions = useSelector(
    actorRef,
    (snapshot) =>
      snapshot?.context?.retailerCheckoutOrderStatus?.retailerSyncSessions
  );
  if (!retailerSyncSessions) {
    return null;
  }

  return (
    <FlowContainer>
      <StyledEnterMFACode
        retailerId={retailerSyncSessions[0].retailerId}
        retailerSyncSession={retailerSyncSessions[0]}
      />
    </FlowContainer>
  );
};

export default CheckoutMFA;
