import { useEffect, useState } from "react";

import {
  PreviewNextCustomerPaymentTransaction,
  getNextPayout,
} from "../../../../../api/graphQl/authenticated/Payouts";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import reduxApolloClient from "../../../../../redux/reduxApolloClient";
import ConfigUtils from "../../../../../utils/ConfigUtils";
import { formatMoney } from "../../../../../utils/StringUtils";
import { withFadeTransition } from "../../../../StoreComponents/StoreTransition";
import { InfoButton } from "./components/InfoButton";
import StoreMessageCard from "../../../../StoreComponents/StoreCard.tsx/StoreMessageCard";
import { useSiteContext } from "../../../../SiteProvider";
import { joinWaitList, sendTextInvite } from "../../../../../api";
import { DashboardHeaderContainer } from "../DashboardHeaderContainer";
import { formatPhoneNumber } from "@brandclub/common-ui";
import { SiteBrandingType } from "../../../../../types/misc";
import {
  handleTotalRewardsClick,
  handleTotalPayoutClick,
  handleTotalAdjustmentsClick,
  handleRewardBalanceClick,
  handlePendingRewardsClick,
  handleLockedRewardsClick,
  handleUnlockedRewardsClick,
  handleCancelledRewardsClick,
  handleCashOutEligibleClick,
} from "./rewardsDialogHandlers";
import { CashoutContainer } from "./styled";
import { loadRewards } from "@/redux/actions/rewards";
import { Skeleton } from "@mui/material";

const BalanceSkeleton = () => {
  return (
    <div className="balance-section">
      <div className="row">
        <Skeleton
          variant="rectangular"
          sx={{
            marginBottom: "30px",
            borderRadius: "10px",
            bgcolor: "#f6f8fa",
          }}
          height={203}
        />
      </div>
      <div className="row">
        <Skeleton
          variant="rectangular"
          sx={{
            marginBottom: "30px",
            borderRadius: "10px",
            bgcolor: "#f6f8fa",
          }}
          height={203}
        />
      </div>
    </div>
  );
};

const GetAppCard = ({ balance }: { balance: number }) => {
  const phoneNumber = useAppSelector(
    ({ userProfile }) => userProfile?.phoneNumber
  );
  const given_name = useAppSelector(
    ({ userProfile }) => userProfile?.given_name
  );
  const appName = useAppSelector(
    ({ appConfig }) => appConfig?.brandAppConfig?.appName || "Brandclub"
  );
  const [send, setSend] = useState<"send" | "not_sent" | "sending">("not_sent");

  const sendGetAppText = async () => {
    try {
      setSend("sending");
      const { data: waitListEntry } = await joinWaitList({
        phoneNumber:
          phoneNumber?.[0] === "+" ? phoneNumber : `+1${phoneNumber}`,
        email: "gettextinvite@stackline.com",
        name: "gettextinvite@stackline.com",
      });
      await sendTextInvite({
        id: waitListEntry.id,
        forceSendInvite: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSend("send");
    }
  };

  const onCtaClick = () => {
    if (send === "not_sent") {
      sendGetAppText();
    }
  };

  const formattedPhone = formatPhoneNumber(phoneNumber);

  const ctaText =
    send === "sending"
      ? "Sending..."
      : send === "not_sent"
      ? "Text link to " + formattedPhone
      : "Sent to " + formattedPhone;

  return (
    <StoreMessageCard
      className="get_app"
      title={`Congrats, ${given_name}!`}
      content={`You have ${formatMoney(balance)} in rewards. Plus, you can
      earn more rewards in the ${appName} app. Download the app to access
      your rewards.`}
      cta={ctaText}
      onActionClick={onCtaClick}
    />
  );
};

const Balance = () => {
  const { siteBrandingType } = useSiteContext();
  const mainEntity = useAppSelector(({ mainEntity }) => mainEntity);
  const dispatch = useAppDispatch();
  const rewards = useAppSelector(({ rewards }) => rewards.rewards);
  const loading = useAppSelector(({ rewards }) => rewards.loading);
  const [nextPayout, setNextPayout] =
    useState<PreviewNextCustomerPaymentTransaction | undefined>();

  useEffect(() => {
    (async () => {
      if (
        mainEntity?.id &&
        (siteBrandingType === SiteBrandingType.CustomStore ||
          siteBrandingType === SiteBrandingType.BrandStore)
      ) {
        dispatch(loadRewards([mainEntity.id]));
      } else {
        dispatch(loadRewards());
      }
    })();
  }, [siteBrandingType, mainEntity, dispatch]);

  const availableBalance = rewards?.availableBalance || 0;
  const unlockedBalance = rewards?.unlockedBalance || 0;
  const lockedBalance = rewards?.lockedBalance || 0;
  const pendingBalance = rewards?.pendingBalance || 0;
  const cancelledBalance = rewards?.cancelledBalance || 0;
  const lifetimeAdjustments = rewards?.lifetimeAdjustments || 0;
  const lifetimeEarnings = rewards?.lifetimeEarnings || 0;
  const lifetimePayouts = rewards?.lifetimePayouts || 0;

  // Do not let it be > unlocked balance (can happen with RDS <> ES sync issues)
  const cashOutEligibleBalance = Math.min(
    nextPayout?.totalCashOutAmount // this will be non zero if there are enough rewards to cover the cash out fee
      ? Math.min(
          (nextPayout?.totalCashOutAmount ?? 0) +
            (nextPayout?.cashOutFeeAmount ?? 0),
          50
        )
      : availableBalance,
    availableBalance
  );

  const fetchNextPayout = async () => {
    try {
      const { data } = await reduxApolloClient.query<{
        PreviewNextCustomerPaymentTransaction: PreviewNextCustomerPaymentTransaction;
      }>({
        query: getNextPayout,
        fetchPolicy: "no-cache",
        context: {
          uri: `${ConfigUtils.getGraphQlUri()}?id=getNextPayoutSummary`,
        },
      });

      setNextPayout(data.PreviewNextCustomerPaymentTransaction);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchNextPayout();
  }, []);

  const rewardBalance =
    lifetimeEarnings - lifetimePayouts - lifetimeAdjustments;

  if (loading) {
    return (
      <>
        <DashboardHeaderContainer>
          <div className="title">Reward balance</div>
        </DashboardHeaderContainer>
        <BalanceSkeleton />
      </>
    );
  }

  return (
    <div>
      <DashboardHeaderContainer>
        <div className="title">Reward balance</div>
      </DashboardHeaderContainer>
      <CashoutContainer>
        <div className="balance-section">
          <div className="row">
            <div className="label">Total rewards</div>
            <div className="value">
              {formatMoney(lifetimeEarnings)}{" "}
              <div role="button" onClick={handleTotalRewardsClick}>
                <InfoButton />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="label">Total payouts</div>
            <div className="value">
              {formatMoney(lifetimePayouts)}{" "}
              <div role="button" onClick={handleTotalPayoutClick}>
                <InfoButton />
              </div>
            </div>
          </div>
          {lifetimeAdjustments ? (
            <div className="row">
              <div className="label">Total adjustments</div>
              <div className="value">
                {formatMoney(lifetimeAdjustments)}{" "}
                <div role="button" onClick={handleTotalAdjustmentsClick}>
                  <InfoButton />
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="label">Reward balance</div>
            <div className="value">
              {formatMoney(rewardBalance)}{" "}
              <div role="button" onClick={handleRewardBalanceClick}>
                <InfoButton />
              </div>
            </div>
          </div>
        </div>
        {nextPayout ? (
          <div className="balance-section">
            <div className="sperator" />
            {pendingBalance ? (
              <div className="row">
                <div className="label">Pending rewards</div>
                <div className="value">
                  {formatMoney(pendingBalance)}{" "}
                  <div role="button" onClick={handlePendingRewardsClick}>
                    <InfoButton />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="label">Locked rewards</div>
              <div className="value">
                {formatMoney(lockedBalance)}{" "}
                <div role="button" onClick={handleLockedRewardsClick}>
                  <InfoButton />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="label">Unlocked rewards</div>
              <div className="value">
                {formatMoney(unlockedBalance)}{" "}
                <div role="button" onClick={handleUnlockedRewardsClick}>
                  <InfoButton />
                </div>
              </div>
            </div>
            {cancelledBalance ? (
              <div className="row">
                <div className="label">Cancelled rewards</div>
                <div className="value">
                  {formatMoney(cancelledBalance)}{" "}
                  <div role="button" onClick={handleCancelledRewardsClick}>
                    <InfoButton />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="label">Cash out eligible</div>
              <div className="value">
                {formatMoney(cashOutEligibleBalance)}{" "}
                <div role="button" onClick={handleCashOutEligibleClick}>
                  <InfoButton />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {rewardBalance ? <GetAppCard balance={rewardBalance} /> : null}
      </CashoutContainer>
    </div>
  );
};

export default withFadeTransition(Balance);
