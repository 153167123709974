import {
  brandclub_colors,
  getPurchaseRewardAmountForProductV2,
  getRetailerFromId,
  ProductRewardSearchResult,
} from "@brandclub/common-ui";
import { Box, Typography, styled } from "@mui/material";
import pluralize from "pluralize";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Loading";

import { getDefaultProductImage } from "../../../../utils/media";
import { formatMoney, getEntityImage } from "../../../../utils/StringUtils";
import {
  OutlineButton,
  SolidButton,
} from "../../../StoreComponents/StoreButton";
import { ShoppingCartItem, UserRetailerCheckoutOrder } from "../../types";

import { useAppDispatch } from "../../../../redux/hooks";
import { sumItemQuantities } from "../../../../utils/misc";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import { useFetchPurchaseDetail } from "../../../pages/AppPages/Dashboard/Purchase/data";
import ProductImageWithQuantity from "../../../pages/AppPages/Dashboard/Purchase/ProductImageWithQuantity";

import { isItemCheckoutSuccess } from "../../utils";
import RewardBlockLink from "../RewardBlockLink";
import { useDashboardLinkPrefix } from "../../../../utils/hooks/useDashboardLinkPrefix";
import { getActiveCarts } from "@/redux/reducers/checkout/thunk";
import { useCheckoutContext } from "../../CheckoutMachine";
import { useSelector } from "@xstate/react";

const getRewardText = (rewardAmount: number, rewardUnlocked: number) => {
  const rewardBlockTitle = `Congratulations!`;
  let rewardBlockText = ``;
  if (rewardAmount > 0 && rewardUnlocked > 0) {
    rewardBlockText = `You earned ${formatMoney(
      rewardAmount
    )} in new rewards and unlocked ${formatMoney(
      rewardUnlocked
    )} in existing rewards.`;
  } else if (rewardAmount > 0) {
    rewardBlockText = `You earned ${formatMoney(rewardAmount)} in new rewards.`;
  } else if (rewardUnlocked > 0) {
    rewardBlockText = `You unlocked ${formatMoney(
      rewardUnlocked
    )} in existing rewards.`;
  }

  return { rewardBlockTitle, rewardBlockText };
};

function formatRetailers(strings: string[]): string {
  if (strings.length === 0) {
    return "";
  } else if (strings.length === 1) {
    return strings[0];
  } else if (strings.length === 2) {
    return strings[0] + " and " + strings[1];
  } else {
    const formattedString =
      strings.slice(0, -1).join(", ") + ", and " + strings[strings.length - 1];
    return formattedString;
  }
}

const ProductRowContainer = styled(Box)({
  display: "flex",
  gap: 15,
  alignItems: "center",
  marginBottom: 25,
  ".product_image": {
    width: 80,
    height: 80,
    objectFit: "contain",
  },
  ".product_detail": {
    display: "flex",
    flexDirection: "column",
    gap: 6,
    flex: 1,
    ".price": {
      fontSize: 16,
      fontWeight: 600,
    },
    ".name": {
      fontSize: 14,
      fontWeight: 500,
      overflow: "hidden",
      letterSpacing: 0.36,
      WebkitLineClamp: 2,
      lineHeight: 1.5,
      height: `3em`, // line height * 3
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      wordBreak: "break-word",
    },
    ".actions": {
      display: "flex",
      gap: 50,
      ".quantity": {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },
});

const ProductRow = ({
  product,
  metadata,
}: {
  product: ShoppingCartItem;
  metadata: ProductRewardSearchResult;
}) => {
  return (
    <ProductRowContainer className="product" gap={15}>
      <ProductImageWithQuantity
        url={getDefaultProductImage(product?.stacklineSku || "")}
        quantity={product.quantity}
      />
      <Box className="product_detail">
        <Box className="info">
          <Typography className="price">
            {formatMoney(product.retailPrice)}
          </Typography>
          <Typography className="name">
            {metadata?.title ?? "Unknown Product"}
          </Typography>
        </Box>
      </Box>
    </ProductRowContainer>
  );
};

const Container = styled("div")({
  margin: "65px auto",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  maxWidth: 732,
  padding: "0",
  alignItems: "center",

  ".success_reward": {
    marginTop: 30,
    width: "100%",
  },
  ".head": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 15,

    width: "100%",
    ".title": {
      fontSize: 24,
      fontWeight: 700,
      textAlign: "center",
      maxWidth: 552,
    },
    ".subtitle": {
      fontSize: 20,
      fontWeight: 500,
      textAlign: "center",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        fontSize: 16,
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 16,
      },
    },
    ".button_group": {
      marginTop: 15,
      display: "flex",
      gap: 25,
      width: "100%",
      flexDirection: "row",

      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        flexDirection: "column",
        alignItems: "center",
        gap: 10,
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        flexDirection: "column",
        alignItems: "center",
        gap: 10,
      },
    },
  },
  ".product_section": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 20,
    ".retailer_cart + .retailer_cart": {
      borderTop: `1px solid ${brandclub_colors.grayLight}`,
    },
    ".retailer_cart": {
      padding: "32px 0 0 0",

      ".product_rows": {
        paddingTop: 30,
        display: "flex",
        flexDirection: "column",
        gap: 30,
      },
    },
    ".retailer": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 16,

      ".retailer_logo": {
        width: 30,
        height: 30,
        objectFit: "contain",
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          width: 25,
          height: 25,
        },
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          width: 25,
          height: 25,
        },
      },
      ".retailer_name": {
        flex: 1,
        fontSize: 20,
        fontWeight: 600,
      },
      ".actions": {},
    },
    ".group_info": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 22,
      marginBottom: 2,
      ".group_count": {
        fontSize: 16,
        fontWeight: 500,
      },
      ".group_subtotal": {
        fontSize: 16,
        fontWeight: 500,
        ".price": {
          fontWeight: 700,
        },
      },
    },
    ".group_summary": {
      display: "flex",
      flexDirection: "column",
      gap: 19,
      marginBottom: 20,
      marginTop: 20,
      ".summary_row": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        ".summary_label": {
          fontSize: 20,
          fontWeight: 500,
        },
        ".summary_value": {
          fontSize: 20,
          fontWeight: 500,
        },
        "&.total": {
          ".summary_label": {
            fontSize: 24,
            fontWeight: 600,
          },
          ".summary_value": {
            fontSize: 24,
            fontWeight: 600,
          },
        },
      },
    },

    ".product": {
      width: "100%",
    },
  },
  ".benefits": {
    boxSizing: "border-box",
    borderRadius: 5,
    padding: "25px 20px 18px 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 5,
    width: "100%",
    border: `1px solid #dcdddc`,
    background: "#f6f8fa",
    marginTop: 35,
    ".title": {
      fontSize: 20,
      fontWeight: 600,
    },
    ".offer": {
      fontSize: 16,
      fontWeight: 500,
      textAlign: "center",
      marginBottom: 15,
    },
  },
});

const getItemsFromOrders = (
  order: UserRetailerCheckoutOrder,
  maxItemsToShow: number
) => {
  const allItems = order.items.filter((item) => isItemCheckoutSuccess(item));
  const itemsToShow = allItems.slice(0, maxItemsToShow);
  const restItems = allItems.slice(maxItemsToShow);
  return [allItems, itemsToShow, restItems];
};

const CheckoutSuccess = () => {
  const { actorRef } = useCheckoutContext();
  const checkoutBatchId = useSelector(
    actorRef,
    (snapshot) => snapshot.context.checkoutBatch?.checkoutBatchId
  );
  const prefix = useDashboardLinkPrefix();
  const dispatch = useAppDispatch();
  const { data, loading } = useFetchPurchaseDetail({
    batchId: checkoutBatchId as string,
    pageSize: 100,
  });

  const items = useMemo(
    () =>
      data.map((d) => ({
        ...d,
        items: d.items.filter((i) => isItemCheckoutSuccess(i)),
      })),
    [data]
  );
  const navigate = useNavigate();

  const retailerNames = items.map((item) => getRetailerFromId(item.retailerId));
  useEffect(() => {
    return () => {
      dispatch(getActiveCarts());
    };
  }, [dispatch]);

  let subtitleText = `Order #${checkoutBatchId} has been confirmed by ${formatRetailers(
    retailerNames
  )}.`;

  const rewardAmount = items?.reduce((acc, item) => {
    if (item.status === "checkout_success") {
      Object.values(item.products).forEach((product) => {
        const { amount } = getPurchaseRewardAmountForProductV2(product);
        acc += amount;
      });
    }
    return acc;
  }, 0);

  const rewardUnlocked = 0;

  const { rewardBlockTitle, rewardBlockText } = getRewardText(
    rewardAmount || 0,
    rewardUnlocked
  );

  return (
    <>
      {loading ? (
        <Loading dot></Loading>
      ) : (
        <Container>
          <div className="head">
            <div className="title">Thanks for your order!</div>
            <div className="subtitle">{subtitleText}</div>
            <div className="button_group">
              <SolidButton
                onClick={() => {
                  navigate(`${prefix}/me`);
                }}
                sx={{
                  width: "100%",
                  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                    maxWidth: "unset",
                  },
                }}
                style={{ flex: 1, height: 50, fontSize: 16 }}
              >
                View member dashboard
              </SolidButton>
              <OutlineButton
                style={{ flex: 1, height: 50, fontSize: 16 }}
                sx={{
                  width: "100%",
                  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                    maxWidth: "unset",
                  },
                }}
                onClick={() => {
                  navigate(`${prefix}/purchase/` + checkoutBatchId);
                }}
              >
                View order receipt
              </OutlineButton>
            </div>
          </div>

          {rewardAmount > 0 && (
            <div className="success_reward">
              <RewardBlockLink
                title={rewardBlockTitle}
                text={rewardBlockText}
              />
            </div>
          )}

          <Box className="product_section">
            {/* Render all carts (those with > 0 items - filtered by redux already) */}
            {items.map((item, index) => {
              const retailerId = item.retailerId;
              const retailerOrderId = item.retailerOrderId;
              const products = item.products;
              if (!retailerOrderId) {
                return null;
              }
              const total = item.orderSummary.total;
              const [allItems, itemsToDisplay, restItems] = getItemsFromOrders(
                item as any,
                2
              );
              const totalNumOfItems = sumItemQuantities(allItems);
              if (totalNumOfItems === 0) {
                return null;
              }
              const restItemsCount = sumItemQuantities(restItems);

              return (
                <Box
                  key={`orderGroup:${retailerOrderId}`}
                  display="flex"
                  flexDirection="column"
                  className="retailer_cart"
                  gap={0}
                >
                  <Box className="retailer">
                    <img
                      src={getEntityImage(retailerId, "retailer")}
                      alt={`Logo for retailer: ${retailerId}`}
                      className="retailer_logo"
                    />
                    <Typography className="retailer_name">
                      {getRetailerFromId(retailerId)}
                    </Typography>
                  </Box>
                  <Box className="group_info">
                    <Box className="group_count">Order #{retailerOrderId}</Box>
                    <Box className="group_subtotal">
                      Total: {formatMoney(total ?? 0)}
                    </Box>
                  </Box>
                  <Box marginTop="7px">
                    <Box fontSize={16} fontWeight={500}>
                      {`${totalNumOfItems} ${pluralize(
                        "item",
                        totalNumOfItems
                      )}`}
                    </Box>
                  </Box>

                  <div className="product_rows">
                    {itemsToDisplay.map((product) => (
                      <ProductRow
                        product={product}
                        metadata={products[product.stacklineSku]}
                        key={`retailer:${retailerId}order:${retailerOrderId}:item:${product.stacklineSku}`}
                      />
                    ))}
                    {restItemsCount > 0 && (
                      <Box
                        fontWeight={500}
                      >{`+${restItemsCount} more ${pluralize(
                        "item",
                        restItemsCount
                      )}`}</Box>
                    )}
                  </div>
                </Box>
              );
            })}
          </Box>
          <div className="benefits">
            <div className="title">Member benefits</div>
            <div className="offer">
              Access exclusive offers and benefits in your member account.
            </div>
            <OutlineButton
              onClick={() => {
                navigate(`${prefix}/me`);
              }}
              style={{ maxWidth: 355, width: "100%", height: 50, fontSize: 16 }}
            >
              View account
            </OutlineButton>
          </div>
        </Container>
      )}
    </>
  );
};

export default CheckoutSuccess;
