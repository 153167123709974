import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import BrandclubLoading from "../../assets/lottie/progress_dot.json";
import { SimplePaletteColorOptions } from "@mui/material/styles";
import { CheckCircleIcon } from "@brandclub/common-ui";
import Lottie from "react-lottie";
import colors from "../styles/colors";
import { Box, styled } from "@mui/material";
import { progressLottieOptions } from "../../utils/lottieUtils";
import { ReactNode } from "react";

const StepContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  ".step_container": {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ProgressLine = styled("div")(() => ({
  borderRadius: 10,
  position: "absolute",
}));

const InactiveDot = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 18,
      height: 18,
      backgroundColor: "#f6f8fa",
      borderRadius: 99,
      position: "absolute",
    }}
  >
    <Box
      sx={{
        width: 9,
        height: 9,
        backgroundColor: "#dcdddc",
        borderRadius: 5,
      }}
    />
  </Box>
);

const CompletedDot = (_props: {
  step: { visible: boolean; success: boolean };
}) => {
  return (
    <CheckCircleIcon
      backgroundColor="primary"
      sx={{ fontSize: 18, position: "absolute" }}
      htmlColor="#ffffff"
    />
  );
};

const ProgressDot = ({ dotSize }: { dotSize: number }) => {
  const theme = useTheme();
  return (
    <Box sx={{ position: "absolute" }}>
      <Lottie
        options={progressLottieOptions(theme, BrandclubLoading)}
        isClickToPauseDisabled
        width={dotSize * 1.2 || 30}
        height={dotSize * 1.2 || 30}
      />
    </Box>
  );
};
const StoreStepper = ({
  steps,
  currentIndex,
  dotSize = 23,
  sectionHeight = 97,
  gap = 14,
  animated = false,
}: {
  steps: { visible: boolean; success: boolean }[];
  currentIndex: number;
  dotSize?: number;
  sectionHeight?: number;
  gap?: number;
  animated?: boolean;
}) => {
  const dotDistance = sectionHeight + gap;
  const theme = useTheme();
  const primaryColor = theme.palette.primary as SimplePaletteColorOptions;

  return (
    <StepContainer>
      <div className="step_container">
        <ProgressLine
          sx={{
            width: dotSize / 6 || 4,
            top: sectionHeight / 2,
            height: (steps.length - 1) * dotDistance + 3,
          }}
        />
        {steps.map((step, idx) => {
          if (idx === steps.length - 1) {
            return null;
          }
          const isNextStepVisible = steps[idx + 1]?.visible;
          return (
            <motion.div
              key={idx}
              style={{
                width: dotSize / 6 || 4,
                top: sectionHeight / 2 + dotDistance * idx,
                height: dotDistance,
                backgroundColor: `rgba(220, 221, 220, 0.5)`,
                borderRadius: 10,
                position: "absolute",
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: step.visible && isNextStepVisible ? 1 : 0 }}
            >
              <motion.div
                style={{
                  width: dotSize / 6 || 4,
                  top: 0,
                  height: 0,
                  backgroundColor: theme.dtc
                    ? primaryColor.main
                    : colors.darkBlue,
                  borderRadius: 10,
                  position: "absolute",
                }}
                initial={{ height: 0 }}
                animate={{ height: currentIndex > idx ? dotDistance : 0 }}
              />
            </motion.div>
          );
        })}

        {steps.map((step, idx) => {
          const isCurrent = idx === currentIndex;
          const isCompleted = idx <= currentIndex;

          let content: ReactNode;
          let stepClassName: string;
          if (animated && isCurrent) {
            content = <ProgressDot dotSize={dotSize} />;
            stepClassName = "active1";
          } else if (isCompleted) {
            content = <CompletedDot step={step} />;
            stepClassName = "active";
          } else {
            content = <InactiveDot />;
            stepClassName = "inactive";
          }

          return (
            <motion.div
              key={idx}
              initial={{ opacity: 0, scale: 0 }}
              animate={{
                opacity: step.visible ? 1 : 0,
                scale: step.visible ? 1 : 0,
              }}
            >
              <motion.div
                className={stepClassName}
                style={{
                  top: sectionHeight / 2 - dotSize / 2,
                  width: dotSize,
                  height: dotSize,
                  marginBottom: dotDistance - dotSize,
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
              >
                {content}
              </motion.div>
            </motion.div>
          );
        })}
      </div>
    </StepContainer>
  );
};

export default StoreStepper;
