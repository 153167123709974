import { useAppSelector } from "../../../redux/hooks";
import { useSiteContext } from "../../SiteProvider";
import { buildFooterConfig } from "./BrandStore/config";
import { useMemo } from "react";
import {
  removeTrailingSlash,
  UNIVERSAL_STORE_SITE,
} from "@brandclub/common-ui";
import { SiteBrandingType } from "../../../types/misc";
import { useSignedIn } from "@/utils/hooks/useSignedIn";

export interface RewardSummaryForBrand {
  lifetimeEarnings: number;
  lifetimePayouts: number;
  lifetimeAdjustments: number;
  lockedBalance: number;
  pendingBalance: number;
  unlockedBalance: number;
  cancelledBalance: number;
  hasLoaded: boolean;
}

interface useFooterConfigsProps {
  rewardSummaryForBrand: RewardSummaryForBrand;
  totalRewardCounts: number;
  currentBalance: number | undefined;
}

export const useFooterConfigs = ({
  rewardSummaryForBrand,
  totalRewardCounts,
  currentBalance,
}: useFooterConfigsProps) => {
  const mobileAppLink = useAppSelector(
    ({ appConfig }) => appConfig?.domainConfig.mobileAppLink
  );
  const storeDescription = useAppSelector(
    ({ appConfig }) => appConfig?.domainConfig.storeDescription
  );
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const { signedIn } = useSignedIn();
  const { siteBrandingType, site, persistentQueryParams, routePrefix } =
    useSiteContext();

  const userRewardAmount =
    rewardSummaryForBrand.lifetimeEarnings -
    rewardSummaryForBrand.lifetimePayouts -
    rewardSummaryForBrand.lifetimeAdjustments;

  const appLink = mobileAppLink
    ? removeTrailingSlash(mobileAppLink) + "/getapp"
    : UNIVERSAL_STORE_SITE + "/getapp";

  return useMemo(() => {
    const config = buildFooterConfig({
      siteBrandingType,
      userRewardAmount,
      userRewardCounts: totalRewardCounts,
      site,
      routePrefix,
      persistentQueryParams,
      signedIn,
      brandEntity,
      appLink,
      customText: storeDescription,
    });

    const universalStoreFooterConfig = buildFooterConfig({
      siteBrandingType: SiteBrandingType.UniversalStore,
      userRewardAmount: currentBalance,
      userRewardCounts: totalRewardCounts,
      site: undefined,
      routePrefix: "",
      persistentQueryParams,
      signedIn,
      appLink,
    });

    if (siteBrandingType === SiteBrandingType.UniversalStore) {
      return [universalStoreFooterConfig];
    }

    if (
      siteBrandingType === SiteBrandingType.CustomStore ||
      siteBrandingType === SiteBrandingType.CustomDTCStore
    ) {
      return [config];
    }

    return [config, universalStoreFooterConfig];
  }, [
    routePrefix,
    siteBrandingType,
    currentBalance,
    totalRewardCounts,
    site,
    persistentQueryParams,
    userRewardAmount,
    brandEntity,
    signedIn,
    appLink,
    storeDescription,
  ]);
};
