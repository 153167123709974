import PhoneNumber from "awesome-phonenumber";
import moment from "moment";
import numeral from "numeral";

import _capitalize from "lodash/capitalize";
import PurchaseImg from "../assets/Cash_back_reward.svg";
import AffiliateImg from "../assets/Affiliate_reward.svg";
import ReviewImg from "../assets/Ratings_reward.svg";
import PromoteImg from "../assets/Promote_reward.svg";
import SurveyImg from "../assets/survey_reward.svg";
import ContentImg from "../assets/Content_reward.svg";
import ScanImg from "../assets/Page_scan_reward.svg";
import LimitedTimePurchaseImg from "../assets/Limited_time_purchase_reward.svg";
import { PaymentStatus, RewardStatus } from "@brandclub/types";
import { Theme } from "@mui/material/styles";
import queryString from "query-string";
import { BrandAppConfig } from "../redux/types";
import ConfigUtils from "./ConfigUtils";

type PhoneNumberFormat =
  | "e164"
  | "international"
  | "national"
  | "rfc3966"
  | "significant";

export const buildReferralLink = (
  brandAppConfig: BrandAppConfig,
  code?: string
) => {
  const queryParams = queryString.stringify(
    removeUndefinedValues({
      referralCode: code,
    })
  );

  const baseUrl = ConfigUtils.getBranchIOLinkPrefix(brandAppConfig);
  return `${baseUrl}appshare?${queryParams}`;
  // `https://brandclub.com/appshare?referralCode=${code}`
};

export const validateBirthDateFormat = (birthDate?: string): boolean => {
  const formatValid = moment(birthDate, "MM/DD/YYYY", true).isValid();
  return formatValid;
};
export const validateBirthDate = (birthDate?: string): boolean => {
  const currentDate = moment();
  const yearsOld = currentDate.diff(
    moment(birthDate, "MM/DD/YYYY", true),
    "years"
  );
  return yearsOld >= 18 && yearsOld <= 100;
};

export const roundFloat = (value: number | string, decimals = 4) => {
  return value ? parseFloat(Number(value).toFixed(decimals)) : 0;
};

export const formatMoney = (
  num: number,
  currencySymbol = "$",
  includeDecimal = true
) => {
  return numeral(roundFloat(num)).format(
    `${currencySymbol}0,0${includeDecimal ? `.00` : ``}`
  );
};

export const formatNumber = (num: number, includeDecimal = true) => {
  return numeral(num).format(`0,0${includeDecimal ? `.00` : ``}`);
};

export const formatPercent = (num: number, includeDecimal = true) => {
  return numeral(num).format(`0,0${includeDecimal ? `.00` : ``} %`);
};

export const formatDate = (date: string | number | Date, unix?: boolean) => {
  if (unix) {
    return moment.unix(date as number).format("MMMM D, YYYY");
  }

  return moment(date).format("MMMM D, YYYY");
};

export const getEntityImage = (
  id: number | string,
  type: "brand" | "product" | "retailer"
) => {
  // TODO: for products, we need the stackline sku, not the retailer sku
  if (type === "retailer") {
    return `https://media.brandclub.com/retailer_logo/${id}.webp`;
  }

  return `https://s3-us-west-2.amazonaws.com/stackline-product-images/${id}.jpg`;
};

export const splitCamelCase = (s: string) =>
  s.replace(/([a-z])([A-Z])/g, "$1 $2");

export const isBlank = (str: string) => {
  return !str || /^\s*$/.test(str);
};

export const getCampaignType = (str: any) => {
  switch (str) {
    case "purchaseRewardCampaign":
      return "Repeat Purchase";
    case "limitedTimePurchaseRewardCampaign":
      return "Daily Deal Purchase";
    case "reviewRewardCampaign":
      return "Review Reward";
    case "socialRewardCampaign":
      return "Social Reward";
    case "referralRewardCampaign":
      return "Referral Reward";
    case "promoteBrandClubRewardCampaign":
      return "Membership Reward";
    case "surveyRewardCampaign":
      return "Survey Reward";
    case "contentRewardCampaign":
      return "Content Reward";
    case "pageScanRewardCampaign":
      return "Page Scanning Reward";
    case "retailerConnectRewardCampaign":
      return "Connect Reward";
    case "affiliateLinkCampaign":
      return "Affiliate Reward";
  }

  return str;
};

export const campaignTypesForBrand = [
  {
    optionValue: "purchaseRewardCampaign",
    title: getCampaignType("purchaseRewardCampaign"),
    subTitle: "Give cashback when a customer purchases a product repeatedly",
    image: PurchaseImg,
  },
  // {
  //   optionValue: "limitedTimePurchaseRewardCampaign",
  //   title: getCampaignType("limitedTimePurchaseRewardCampaign"),
  //   subTitle: "Give instant cashback when a customer purchases a product",
  //   image: LimitedTimePurchaseImg,
  // },
  {
    optionValue: "affiliateLinkCampaign",
    title: getCampaignType("affiliateLinkCampaign"),
    subTitle: "Give cashback when a user refers someone to purchase a product",
    image: AffiliateImg,
  },
  {
    optionValue: "reviewRewardCampaign",
    title: getCampaignType("reviewRewardCampaign"),
    subTitle: "Give cashback when a customer reviews a product",
    image: ReviewImg,
  },
  {
    optionValue: "promoteBrandClubRewardCampaign",
    title: getCampaignType("promoteBrandClubRewardCampaign"),
    subTitle:
      "Give cashback when a customer joins or refers someone to your Brandclub",
    image: PromoteImg,
  },
  {
    optionValue: "surveyRewardCampaign",
    title: getCampaignType("surveyRewardCampaign"),
    subTitle: "Give rewards when a user completes a survey",
    image: SurveyImg,
  },
  {
    optionValue: "contentRewardCampaign",
    title: getCampaignType("contentRewardCampaign"),
    subTitle: "Give rewards when a user watches content",
    image: SurveyImg,
  },
];

export const campaignTypesForSuperUserCompany = [
  {
    optionValue: "purchaseRewardCampaign",
    title: getCampaignType("purchaseRewardCampaign"),
    subTitle: "Give cashback when a customer purchases a product repeatedly",
    image: PurchaseImg,
  },
  {
    optionValue: "limitedTimePurchaseRewardCampaign",
    title: getCampaignType("limitedTimePurchaseRewardCampaign"),
    subTitle: "Give daily deals cashback when a customer purchases a product",
    image: LimitedTimePurchaseImg,
  },
  {
    optionValue: "pageScanRewardCampaign",
    title: getCampaignType("pageScanRewardCampaign"),
    subTitle: "Give cashback when a customer scans an order page",
    image: ScanImg,
  },
  {
    optionValue: "retailerConnectRewardCampaign",
    title: getCampaignType("retailerConnectRewardCampaign"),
    subTitle:
      "Give cashback when a customer connects a retailer for the first time",
    image: ScanImg,
  },
  {
    optionValue: "affiliateLinkCampaign",
    title: getCampaignType("affiliateLinkCampaign"),
    subTitle: "Give cashback when a user refers someone to purchase a product",
    image: AffiliateImg,
  },
  {
    optionValue: "reviewRewardCampaign",
    title: getCampaignType("reviewRewardCampaign"),
    subTitle: "Give cashback when a customer reviews a product",
    image: ReviewImg,
  },
  {
    optionValue: "promoteBrandClubRewardCampaign",
    title: getCampaignType("promoteBrandClubRewardCampaign"),
    subTitle:
      "Give cashback when a customer joins or refers someone to your Brandclub",
    image: PromoteImg,
  },
  {
    optionValue: "referralRewardCampaign",
    title: getCampaignType("referralRewardCampaign"),
    subTitle: "Give a reward to users who refer someone to download the app",
    image: PromoteImg,
  },
  {
    optionValue: "surveyRewardCampaign",
    title: getCampaignType("surveyRewardCampaign"),
    subTitle: "Give rewards when a user completes a survey",
    image: SurveyImg,
  },
  {
    optionValue: "contentRewardCampaign",
    title: getCampaignType("contentRewardCampaign"),
    subTitle: "Give rewards when a user watches content",
    image: SurveyImg,
  },
  {
    optionValue: "socialRewardCampaign",
    title: getCampaignType("socialRewardCampaign"),
    subTitle: "Give cashback when a customer creates a post on social media",
    image: ContentImg,
  },
];

export const isValidPassword = (str: string) => {
  // Minimum eight characters
  return (str || "").length >= 8;
};

export const validateEmail = (email?: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone: string) => {
  const pn = new PhoneNumber(phone, "US");
  // Bypass login for demo account
  if (pn.getNumber() === "+15555555555") return true;
  if (!phone) return false;
  return pn.isValid();
};

export const formatPhone = (phone: string, type?: PhoneNumberFormat) => {
  const pn = new PhoneNumber(phone, "US");
  return pn.getNumber(type);
};

export const isJSON = (str: string): boolean => {
  try {
    var obj = JSON.parse(str);
    if (obj && typeof obj === "object" && obj !== null) {
      return true;
    }
  } catch (err) {}
  return false;
};

export const isEmptyString = (str?: string): boolean => {
  return typeof str === "undefined" || str.trim() === "";
};

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
};

// this stringAvatar() use together with Avatar
export const stringAvatar = (name: string) => {
  const nameSplit = name.split(" ");
  let initials = nameSplit[0][0];
  if (nameSplit.length > 1) {
    initials = `${initials}${nameSplit[1][0]}`;
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
};

export const isImageSrcExist = (url: string) => {
  return new Promise(function (resolve, reject) {
    var img = new Image();
    img.onload = function () {
      resolve(true);
    };
    img.onerror = function () {
      resolve(false);
    };
    img.src = url;
  });
};

export const escapeUriPath = (value: any, spaceReplacementChar?: string) => {
  let valueString = `${value}`;
  valueString = valueString
    .trim()
    .replace(/[^\w\s]/gi, "")
    .replace(/ +/g, spaceReplacementChar ? spaceReplacementChar : "-");
  return valueString;
};

export const formatFullName = (
  firstName: string | undefined,
  lastName: string | undefined
) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else {
    return firstName || lastName || "";
  }
};

export const formatFullCityStateZip = (
  city?: string | null,
  state?: string | null,
  zip?: string | null
) => {
  let formattedAddress = "";

  if (city) {
    formattedAddress += city;
  }

  if (state) {
    if (formattedAddress) {
      formattedAddress += ", ";
    }
    formattedAddress += state;
  }

  if (zip) {
    if (formattedAddress) {
      formattedAddress += " ";
    }
    formattedAddress += zip;
  }

  return formattedAddress;
};

const parseUndefined = (...values: Array<string | undefined | null>) => {
  return values.map((value) =>
    value === "undefined" || value === null ? undefined : value
  );
};

const formatFullAddress = (
  address1?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null
) => {
  const [parsedAddress1, parsedCity, parsedState, parsedZip] = parseUndefined(
    address1,
    city,
    state,
    zip
  );

  const cityStateZip = formatFullCityStateZip(
    parsedCity,
    parsedState,
    parsedZip
  );
  if (parsedAddress1 && cityStateZip) {
    return `${parsedAddress1}, ${cityStateZip}`;
  }
  return parsedAddress1 || cityStateZip;
};

interface Address {
  address1?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}
export const getFullAddressWithNames = (address: Address) => {
  const [firstName, lastName] = parseUndefined(
    address.firstName,
    address.lastName
  );
  const name = formatFullName(firstName, lastName);
  const fullAddress = formatFullAddress(
    address.address1,
    address.city,
    address.state,
    address.zipCode
  );
  if (fullAddress) {
    return `${name} - ${fullAddress}`;
  }
  return `${name}`;
};

export const generateStatus = (
  rewardStatus: RewardStatus,
  paymentStatus: PaymentStatus
) => {
  if (rewardStatus === "locked") {
    return "Locked reward"; // Waiting for next purchase
  }
  if (rewardStatus === "pending") {
    return "Pending reward"; // Waiting for confirmed delivery status on the purchase
  }
  if (rewardStatus === "redacted") {
    return "Cancelled reward"; // User returned/cancelled order
  } else {
    if (paymentStatus === "pendingCashOut") {
      return "Pending cash out";
    } else if (paymentStatus === "cashOutSuccess") {
      return "Cashed out";
    } else if (paymentStatus === "cashOutFailed") {
      return "Cash out under review";
    } else if (paymentStatus === "eligible") {
      return "Unlocked reward";
    }
    return "";
  }
};

export const generateStatusDetail = (
  status: string,
  brandName?: string,
  rewardType?: string
) => {
  if (status === "Locked reward") {
    if (rewardType === "syncReward") {
      return `Sync your account to unlock`;
    }
    return `Purchase a ${brandName} product to unlock`;
  } else if (status === "Unlocked reward") {
    return "This reward is now available to cash out";
  } else if (status === "Cashed out") {
    return "Sent payout to your financial account";
  } else if (status === "Cash out under review") {
    return "Your transaction is being reviewed.";
  } else if (status === "Pending reward") {
    return `Reward is awaiting retailer confirmation`;
  } else if (status === "Cancelled reward") {
    return `Reward was removed due to order cancellation`;
  } else if (status === "Pending cash out") {
    return `Your cash out request is processing`;
  }
};

export const generatePayoutStatus = (status: string) => {
  switch (status) {
    case "success":
      return "Available";
    default:
      return _capitalize(status);
  }
};

export const isParentProduct = (stacklineSku: string) => {
  const regex = new RegExp("^(?:[0-9]_{1}:?){1}");
  return regex.test(stacklineSku);
};

export const idExpiryValid = (expiry: string) => {
  const pattern = /^\d{2}\/\d{2}$/;
  return pattern.test(expiry);
};

export const removeSpaces = (str: string) => {
  // Use the replace method to replace all spaces with an empty string
  const output = str.replace(/\s/g, "");

  // Return the resulting string
  return output;
};

export const getDateFromExpiry = (expiry: string) => {
  const [month, year] = expiry
    .split("/")
    .map((num) => parseInt(num.slice(0, 2)));
  return {
    month,
    year,
  };
};

export const formatExpiry = (year?: number, month?: number) => {
  if (year && month) {
    return moment()
      .set({ year, month: month - 1 })
      .format("MM/YY");
  }
  return "";
};

/**
 * Formats an error key by replacing capital letters with spaces and converting it to lowercase.
 *
 * @param key The error key to format.
 * @returns The formatted error key.
 *
 * @example
 * const key = "firstName";
 * console.log(formatErrorKey(key));
 * // Output: "first name"
 */
const formatErrorKey = (key: string): string => {
  return key.replace(/([A-Z])/g, " $1").toLowerCase();
};

/**
 * Generates an error message based on the provided error object.
 *
 * @param errors The error object containing key-value pairs of errors.
 * @param multipleErrorsMessage The error message to return if there are more than 2 error key-value pairs
 * @param formatErrorMsg The function to call to get the error message to display when there are less than 2 error key-value pairs
 * @returns The generated error message or undefined if there are no errors.
 *
 * @example
 * const errors = { firstName: 'cannot be empty', password: 'must be longer than 8 characters' };
 * const errorMessage = getErrorMessage(errors);
 * console.log(errorMessage);
 * // Output: "first name cannot be empty and password must be longer than 8 characters"
 */
export const getErrorMessageFromObject = <
  T extends Partial<Record<K, string>>,
  K extends keyof T & string
>(
  errors: T,
  multipleErrorsMessage: string,
  formatErrorMsg?: (formattedErr: {
    key1: K;
    key2?: K;
    formattedKey1: string;
    formattedKey2?: string;
    errorValue1?: string;
    errorValue2?: string;
  }) => string
): string | undefined => {
  const errorEntries = Object.entries(errors).filter(
    ([, value]) => !!value
  ) as Array<[K, string]>;
  const errorCount = errorEntries.length;

  if (errorCount === 0) {
    return undefined;
  }

  if (errorCount === 1) {
    const [key, value] = errorEntries[0];
    const formattedKey = formatErrorKey(key);
    return formatErrorMsg
      ? formatErrorMsg({
          formattedKey1: formattedKey,
          key1: key,
          errorValue1: value,
        })
      : `${_capitalize(formattedKey)} ${value}`;
  }

  if (errorCount === 2) {
    const [key1, value1] = errorEntries[0];
    const [key2, value2] = errorEntries[1];
    const formattedKey1 = formatErrorKey(key1);
    const formattedKey2 = formatErrorKey(key2);
    if (formatErrorMsg) {
      return formatErrorMsg({
        formattedKey1,
        formattedKey2,
        key1,
        key2,
        errorValue1: value1,
        errorValue2: value2,
      });
    }
    return value1 === value2
      ? `${_capitalize(formattedKey1)} and ${formattedKey2} ${
          value1.startsWith("is ") ? value1.replace(/^is /, "are ") : value1 // proper grammar fix (is -> are)
        }`
      : `${_capitalize(
          formattedKey1
        )} ${value1} and ${formattedKey2} ${value2}`;
  }

  return multipleErrorsMessage;
};

export const isWhite = (color: string) => {
  const patterns = [
    /^white$/, // Word "white"
    /^#fff$/, // Short hex code
    /^#ffffff$/, // Hex code
    /^rgb\(255,\s*255,\s*255\)$/i, // RGB
    /^rgba\(255,\s*255,\s*255,?\s*(1|1\.0*)\)$/i, // RGBA with opacity 1 or omitted
  ];

  return patterns.some((pattern) => pattern.test(color));
};

export const joinClassNames = (
  ...classNames: (string | undefined)[]
): string => {
  return classNames
    .map((c) => c?.trim())
    .filter((className) => !!className)
    .join(" ");
};

export const numberToString = (num: number) => {
  if (Number.isFinite(num)) {
    return num.toString();
  }
  return "";
};

export function removeUndefinedValues<T extends Record<string, any>>(
  obj: T
): T {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => !!value)
  ) as T;
}

/**
 * Returns an appropriate contrast color based on the provided color and the theme's primary color.
 * If there is no contrast issue between the theme's primary color and the provided color, the primary color is returned.
 * Otherwise, the contrast color (white) is returned.
 */
export const getContrastPrimaryColor = (color: string, theme: Theme) => {
  // get the appropriate color comparing the theme's primary color and the provided color
  const contrastColor = theme.palette.getContrastText(color);

  /**
   * we assume we have light theme. So if the contrast color is white,
   * it means there is poor contrast between the provided color and the theme's primary color,
   * meaning they're both dark.
   **/
  const isPoorContrast = isWhite(contrastColor);
  return isPoorContrast ? contrastColor : theme.palette.primary.main;
};

export const getHeaderTextColor = (theme: Theme) => {
  return theme.palette.headerColor?.main
    ? getContrastPrimaryColor(theme.palette.headerColor.main, theme)
    : theme.palette.primary.main;
};

/**
 * Returns the appropriate text color and background color for a child element based on the provided container background color.
 *
 * For example, if a container has a dark background color, and you're determining the colors for a button within that container:
 * - The button's background color will be white
 * - The button's text color will be the theme's primary color
 *
 * If the container has a light background color:
 * - The button's background color will be the theme's primary color
 * - The button's text color will be white
 *
 * @param containerBackgroundColor The background color of the parent container
 * @param theme The Material-UI theme object
 * @returns An object with color and backgroundColor properties
 */
export const getContrastingChildColors = (
  containerBackgroundColor: string,
  theme: Theme
) => {
  const childBackgroundColor = getContrastPrimaryColor(
    containerBackgroundColor,
    theme
  );
  const childTextColor = getContrastPrimaryColor(childBackgroundColor, theme);

  return {
    color: childTextColor,
    backgroundColor: childBackgroundColor,
  };
};

export const getHeaderContrastingChildColors = (theme: Theme) => {
  if (theme.palette.headerColor?.main) {
    return getContrastingChildColors(theme.palette.headerColor.main, theme);
  }
  return {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  };
};

export const getPhoneNumberMasking = (phone?: string) => {
  // filter all () and - and space from phone number
  const phoneNumber = phone?.replace(/[\s()-]/g, "");

  if (!phoneNumber) {
    return "############################";
  } else if (phoneNumber.length <= 3) {
    return `#####`;
  } else if (phoneNumber.length <= 7) {
    return `###-#####`;
  } else if (phoneNumber.length <= 10) {
    return `(###) ###-####`;
  } else {
    return "############################";
  }
};
