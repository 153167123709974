import FlowContainer from "../../../pages/AppPages/Pages/components/FlowContainer";
import { useCheckoutContext } from "@/components/Checkout/CheckoutMachine";
import ChooseMFAOption from "../../../pages/AppPages/Sync/MFA/ChooseMFAOption";
import { useSelector } from "@xstate/react";
import { styled } from "@mui/material/styles";
import { TABLET_SCREEN_SIZE } from "@brandclub/common-ui";

const StyledChooseMFAOption = styled(ChooseMFAOption)({
  ".auth_container": {
    [`@media screen and (min-width: ${TABLET_SCREEN_SIZE}px)`]: {
      paddingTop: 175,
    },
  },
});

const CheckoutMFAChoose = () => {
  const { actorRef } = useCheckoutContext();
  const retailerSyncSessions = useSelector(
    actorRef,
    (snapshot) =>
      snapshot.context?.retailerCheckoutOrderStatus?.retailerSyncSessions
  );
  if (!retailerSyncSessions) {
    return null;
  }

  return (
    <FlowContainer>
      <StyledChooseMFAOption
        retailerId={retailerSyncSessions[0].retailerId}
        retailerSyncSession={retailerSyncSessions[0]}
      />
    </FlowContainer>
  );
};

export default CheckoutMFAChoose;
